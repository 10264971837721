// TODO: This file was created by bulk-decaffeinate.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
function LoadingPanel(props) {
  if (!props.loading_payment) {
    return null;
  }

  return (
    <div className="loading-panel">
      <h3 className="lp-heading">Sending Payment information</h3>
      <div id="loading-spinner"></div>
      <br />
      <p className="lp-content">Please do not reload the page.</p>
    </div>
  );
}

import * as React from "react";
import * as ReactDom from "react-dom";
import * as currency from "currency.js";
import { Spinner } from "spin.js";
import * as Toastify from "toastify-js";

import { PricePointSelect } from "./price_point_select.js";

// var debounce = require('lodash.debounce');
// process.env.STRIPE_PUBLISHABLE
let stripe = Stripe(process.env.STRIPE_PUBLISHABLE);
let elements = stripe.elements();

// Create an instance of the card UI component

let card = elements.create("card", {
  style: {
    base: {
      fontFamily: "Arial, sans-serif",
      fontSize: "30px",
      color: "#C1C7CD",
    },
    invalid: {
      color: "red",
    },
  },
});

export default class DirectPaymentForm extends React.Component {
  static displayName = "DirectPaymentForm";

  constructor(props) {
    super(props);
    let altButtonStyles = {
      color: this.props.buttonStyles.backgroundColor,
      backgroundColor: this.props.buttonStyles.color,
      border: `2px solid ${this.props.buttonStyles.backgroundColor}`,
    };

    this.state = {
      current_camper: "",
      liability_waiver: false,
      shirt_size: "",
      custom_data: {},
      graduation_year: "",
      discount_code: "",
      button_available: false,
      discounted: false,
      loading_payment: false,
      price_point_id: this.props.price_point_id,
      price_points: this.props.price_points,
      camper_id: this.props.camper_id,
      camp_id: this.props.camper_id,
      registration_id: this.props.registration_id,
      alt_button_styles: altButtonStyles,
    };
  }

  monthName = () => {
    const monthNumberStartingFromZero = new Date().getMonth();
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][monthNumberStartingFromZero].toString();
  };

  currentMonthNumber = () => {
    const monthNumberStartingFromZero = new Date().getMonth();
    return [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ][monthNumberStartingFromZero].toString();
  };

  componentDidMount() {
    card.mount("#card-element");

    card.addEventListener("change", function (event) {
      let displayError = document.getElementById("card-errors");
      let submitButton = document.getElementById("sub-btn");
      if (event.complete) {
        submitButton.removeAttribute("disabled");
      } else if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });

    let pricePoints = JSON.parse(this.props.price_points);
    let pricePoint = pricePoints.find(
      (obj) => obj.id == this.props.price_point_id
    );
    this.setCartFields(pricePoint, this.state.discounted);
  }

  componentDidUpdate() {
    let target = document.getElementById("loading-spinner");
    if (target != null) {
      new Spinner({ color: "#fff", lines: 10, speed: 1 }).spin(target);
    }
  }

  componentWillUnmount() {
    this.setState({ current_camper: "", liability_waiver: null });
  }

  valid = () => {
    return this.state.button_available;
  };

  validateDiscountCodeLength = (field) => {
    if (field.length > 5) {
      return true;
    }
  };

  validDiscountCode = () => {
    return this.validateDiscountCodeLength(this.state.discount_code);
  };

  resetDiscountCode = () => {
    Toastify({
      text: "Discount Removed",
      duration: 3000,
      close: false,
      position: "right", // `left`, `center` or `right`
      backgroundColor: "#dc3545",
      className: "discount-failure",
      stopOnFocus: true, // Prevents dismissing of toast on hover
    }).showToast();
    let discountRow = document.getElementById("discount-items");
    let discountTag = document.getElementById("discount-tag");
    let cancelDiscount = document.getElementById("remove-discount-code");

    cancelDiscount.classList.add("hidden");
    discountTag.classList.add("hidden");
    discountRow.classList.add("hidden");

    let pricePoints = JSON.parse(this.props.price_points);
    let pricePoint = pricePoints.find(
      (obj) => obj.id == this.state.price_point_id
    );
    this.setCartFields(pricePoint, false);
    this.setState({ discounted: false, discount_code: "" });
    return this.forceUpdate();
  };

  checkInput = (e) => {
    const itemClass = e.target.className;
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    this.showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  setCartFields = (pricePoint, discounted) => {
    let subTotalCents = currency(pricePoint.price_cents, { fromCents: true });

    let discountCents = currency(this.state.discount_amount, {
      fromCents: true,
    });
    let amountOff;

    let subTotalField = document.getElementById("subtotal");
    let totalField = document.getElementById("total-value");

    if (discounted) {
      let discountRow = document.getElementById("discount-items");
      let discount = document.getElementById("discount");
      let discountTag = document.getElementById("discount-tag");
      let cancelDiscount = document.getElementById("remove-discount-code");
      discount.classList.add("price-value");
      discountRow.classList.remove("hidden");
      cancelDiscount.classList.remove("hidden");

      if (this.state.discount_type == "percentage") {
        let percentOff = discountCents.divide(100);
        if (percentOff > 1) {
          amountOff = currency(0, { fromCents: true });
          discount.innerText = "- " + amountOff.format();
          Toastify({
            text: "Discount Invalid - cannot apply discount for more than 100%",
            duration: 3000,
            close: false,
            position: "right", // `left`, `center` or `right`
            backgroundColor: "#dc3545",
            className: "discount-failure",
            stopOnFocus: true, // Prevents dismissing of toast on hover
          }).showToast();
        } else {
          amountOff = subTotalCents.multiply(percentOff);
          discount.innerText = "- " + amountOff.format();
          discountTag.classList.remove("hidden");
          discountTag.innerText = this.state.discount_code;
          Toastify({
            text: "Discount Successful!",
            className: "discount-success",
            duration: 3000,
            newWindow: false,
            close: false,
            gravity: "top", // `left`, `center` or `right`
            position: "right", // `left`, `center` or `right`
            backgroundColor: "#3ecf8e",
            stopOnFocus: true,
          }).showToast();
        }
      } else {
        if (discountCents.dollars() > subTotalCents.dollars()) {
          amountOff = currency(0, { fromCents: true });
          discount.innerText = "- " + amountOff.format();
          Toastify({
            text: "Discount Invalid - cannot apply discount for more than price",
            duration: 3000,
            close: false,
            position: "right", // `left`, `center` or `right`
            backgroundColor: "#dc3545",
            className: "discount-failure",
            stopOnFocus: true, // Prevents dismissing of toast on hover
          }).showToast();
        } else {
          amountOff = discountCents;
          discount.innerText = "- " + discountCents.format();
          discountTag.classList.remove("hidden");
          discountTag.innerText = this.state.discount_code;
          Toastify({
            text: "Discount Successful!",
            duration: 3000,
            newWindow: false,
            close: false,
            className: "discount-success",
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            backgroundColor: "#3ecf8e",
            stopOnFocus: true,
          }).showToast();
        }
      }
    }

    if (subTotalField) {
      subTotalField.classList.add("price-value");
      subTotalField.innerText = subTotalCents.format();
    }

    let newSubTotalCents = subTotalCents.subtract(amountOff);
    let feeCents;

    if (this.props.fee_breakout) {
      let firewoodFeeCents = newSubTotalCents.multiply(
        this.props.fee_percentage
      );
      let stripeFixedCents = currency(30, {
        fromCents: true,
      });
      let updatedSubTotalCents = newSubTotalCents
        .add(firewoodFeeCents)
        .add(stripeFixedCents);
      let newTotalWithStripeFee = updatedSubTotalCents.divide(0.971);
      feeCents = newTotalWithStripeFee.subtract(newSubTotalCents);
      let feeRow = document.getElementById("fee-row");
      let feeTotal = document.getElementById("fee");
      subTotalCents = newTotalWithStripeFee;
      // feeRow.classList.remove('hidden')
      feeTotal.classList.add("price-value");
      feeTotal.innerText = feeCents.format();
    } else {
      subTotalCents = newSubTotalCents;
      feeCents = currency(0, { fromCents: true });
    }

    let newTotalCents = subTotalCents || currency(0, { fromCents: true });
    totalField.innerText = newTotalCents.format();
  };

  pricePointDropdownChanged = (newId) => {
    this.state.price_point_id = newId;
    let pricePoints = JSON.parse(this.props.price_points);
    let pricePoint = pricePoints.find((obj) => obj.id == newId);
    this.setCartFields(pricePoint, this.state.discounted);
    return this.forceUpdate();
  };

  handleChange = (e) => {
    const name = $(e.target).data("name");
    this.setState({ [name]: e.target.value });
  };

  createToken = () => {
    function stripeTokenHandler(token) {
      let form = document.getElementById("new_registration");
      let hiddenInput = document.createElement("input");
      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("name", "payment[stripeToken]");
      hiddenInput.setAttribute("value", token.id);
      form.appendChild(hiddenInput);
      form.submit();
    }
    stripe.createToken(card).then(function (result) {
      if (result.error) {
        // Inform the user if there was an error
        var errorElement = document.getElementById("card-errors");
        errorElement.textContent = result.error.message;
        this.setState({ loading_payment: false, disabled: false });
      } else {
        stripeTokenHandler(result.token);
      }
    });
  };

  handleDiscountCode = (e) => {
    let code = $(".discount-box").val();
    if (this.state.discounted == false) {
      let discountRow = document.getElementById("discount-items");
      let discount = document.getElementById("discount");
      $.post(
        "/verify_discount",
        { discount: { camp_id: this.props.camp_id, code } },
        (data) => {
          if (data.status === 200) {
            let discountData = data.discount_data;
            if (discountData) {
              let discountType = discountData.discount_type;
              let discountAmountCents = discountData.amount_cents;
              this.setState({
                discounted: true,
                discount_amount: discountAmountCents,
                discount_type: discountType,
              });
              let pricePoints = JSON.parse(this.props.price_points);
              let pricePoint = pricePoints.find(
                (obj) => obj.id == this.state.price_point_id
              );
              this.setCartFields(pricePoint, true);
            }
          } else {
            this.setState({
              discounted: false,
              discount_amount: 0,
              discount_type: null,
            });
            discount.classList.add("price-value");
            discountRow.classList.remove("hidden");
            let nonDiscountAmountOff = currency(0, { fromCents: true });
            discount.innerText = "- " + nonDiscountAmountOff.format();

            Toastify({
              text: "Discount Invalid",
              duration: 3000,
              newWindow: false,
              close: false,
              className: "discount-failure",
              gravity: "top", // `top` or `bottom`
              position: "right", // `left`, `center` or `right`
              backgroundColor: "#dc3545",
              stopOnFocus: true,
            }).showToast();
          }
        }
      );
    }
  };

  handleSubmit = (e) => {
    let form = document.getElementById("new_registration");
    this.setState({ loading_payment: true, disabled: true });
    e.preventDefault();
    this.createToken();
  };

  render() {
    return (
      <form
        className={"new-step-form"}
        width={"100%"}
        id={"new_registration"}
        method={"POST"}
        action={`/camps/${this.props.camp_id}/registrations/${this.props.registration_id}/direct_create`}
      >
        <div className={""}>
          <h1 className={"form-heading"}>{"Finalize Payment"}</h1>
          <div className="purchase-recap">
            <PricePointSelect
              price_points={JSON.parse(this.props.price_points)}
              primary_price_point={this.props.price_point}
              onChange={this.pricePointDropdownChanged}
            />
          </div>
        </div>
        <div className={"payment-column"}>
          <input key={"utf8"} name={"utf8"} type={"hidden"} value={"✓"} />
          <input
            key={"_method"}
            name={"_method"}
            type={"hidden"}
            value={"put"}
          />
          <input
            key={"authenticity_token"}
            name={"authenticity_token"}
            type={"hidden"}
            value={this.props.crsf_token}
          />
          <input
            key={"registration[camper_id]"}
            name={"registration[camper_id]"}
            type={"hidden"}
            value={this.props.camper_id}
          />
          <input
            key={"registration[price_point_id]"}
            name={"registration[price_point_id]"}
            type={"hidden"}
            value={this.props.price_point_id}
          />
        </div>
        <LoadingPanel loading_payment={this.state.loading_payment} />
        <div className={"camper-info-row"}>
          <div id={"card-element"} className={"full-field"}></div>
          <div id={"card-errors"} role={"alert"}></div>
        </div>
        <div className={"camper-info-row"}>
          <img
            className={"visa-cc"}
            src={
              "https://res.cloudinary.com/buck/image/upload/v1454771871/visa.png"
            }
            alt={"we take visa"}
          />
          <img
            className={"master-cc"}
            src={
              "https://res.cloudinary.com/buck/image/upload/v1454771871/mastercard.png"
            }
            alt={"we take mastercard"}
          />
          <img
            className={"disc-cc"}
            src={
              "https://res.cloudinary.com/buck/image/upload/v1454771870/discover.png"
            }
            alt={"we take discover"}
          />
          <img
            className={"amex-cc"}
            src={
              "https://res.cloudinary.com/buck/image/upload/v1454771871/amex.png"
            }
            alt={"we take amex"}
          />
        </div>
        <div className={"discount-row"}>
          <button
            type={"button"}
            id={"remove-discount-code"}
            className={"remove-discount-code hidden"}
            onClick={this.resetDiscountCode}
          >
            {"Remove"}
          </button>
          <input
            className={"discount-box half-field"}
            name={"payment[discount_code]"}
            type={"text"}
            value={this.state.discount_code}
            size={30}
            maxLength={30}
            placeholder={"Enter Discount Code..."}
            ref={"discount_code"}
            data-name={"discount_code"}
            onChange={this.handleChange}
          />
          <button
            disabled={this.state.discounted}
            className={"verify-discount-code"}
            style={this.state.alt_button_styles}
            type={"button"}
            onClick={this.handleDiscountCode}
          >
            {"Apply"}
          </button>
        </div>
        <div className={"camper-info-row"}>
          <button
            type={"submit"}
            id={"sub-btn"}
            className={"button button-primary create payment-button"}
            disabled={this.state.disabled}
            style={this.props.buttonStyles}
            onClick={this.handleSubmit}
          >
            {"Register"}
          </button>
        </div>
      </form>
    );
  }
}
