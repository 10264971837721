// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as React from "react";
import * as ReactDom from "react-dom";

export default class HsTeamForm extends React.Component {
  static displayName = "HsTeamForm";
  constructor(props) {
    super(props);
    this.state = {
      height: "",
      weight: "",
      position: "",
      gpa: "",
      sat: "",
      club_team: "",
      shirt_size: "Small",
    };
  }

  componentDidMount() {}

  valid = () => {
    return this.state.height && this.state.weight && this.state.club_team;
  };

  validateLength = (field, requiredLength) => {
    if (field.length > requiredLength) {
      return true;
    }
  };

  checkInput = (e) => {
    const itemClass = e.target.className;
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    return this.showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      const errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      return $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      return $(errorSpan).html("");
    }
  };

  sportSpecificPositionSelect = (sport, restriction) => {
    if (sport === "soccer") {
      return ["Forward", "Midfielder", "Defender", "Goalkeeper"];
    } else if (sport === "basketball") {
      return ["PG", "SG", "SF", "PF", "C"];
    } else if (sport === "volleyball") {
      return [
        "Outside Hitter",
        "Right Side Hitter",
        "Opposite Hitter",
        "Setter",
        "Middle Blocker",
        "Libero",
      ];
    } else {
      return ["All"];
    }
  };

  shirtSizes = () => {
    return ["Small", "Medium", "Large", "X-Large"];
  };

  handleChange = (e) => {
    const { name } = e.target;
    return this.setState({ [name]: e.target.value });
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    const data = {
      height: this.state.height,
      weight: this.state.weight,
      position: this.state.position,
      club_team: this.state.club_team,
      gpa: this.state.gpa,
      sat: this.state.sat,
      act_score: this.state.act_score,
      shirt_size: this.state.shirt_size,
    };
    this.props.saveValues(data);
    return this.props.nextStep();
  };

  render() {
    return React.DOM.form(
      { className: "step-form" },
      React.DOM.div,
      React.DOM.h1({ className: "form-heading" }, "Step 3. Soccer Information"),
      React.DOM.div(
        { className: "camper-info-row" },
        React.DOM.div(
          { className: "half" },
          React.DOM.span({
            className: "error-span",
          }),
          React.DOM.label,
          "Position: ",
          React.DOM.select(
            {
              className: "position",
              name: "position",
              ref: "position",
              onChange: this.handleChange,
            },
            Array.from(this.sportSpecificPositionSelect("soccer")).map(
              (position) =>
                React.DOM.option({ value: position, key: position }, position)
            )
          )
        ),
        React.DOM.div(
          { className: "half" },
          React.DOM.label({ className: "" }, "Club Team:"),
          React.DOM.span({
            className: "error-span",
          }),
          React.DOM.input({
            type: "text",
            className: "club-team",
            placeholder: "Club Team",
            name: "club_team",
            ref: "club_team",
            required: "true",
            "data-error-message":
              "Club Team must be at least one character - N/A is a valid response.",
            "data-min-length": "1",
            value: this.state.club_team,
            onChange: this.handleChange,
            onBlur: this.checkInput,
          })
        )
      ),
      React.DOM.div(
        { className: "camper-info-row" },
        React.DOM.div(
          { className: "half" },
          React.DOM.label,
          "Height: *",
          React.DOM.span({
            className: "error-span",
          }),
          React.DOM.input({
            type: "text",
            className: "height",
            placeholder: "Enter Height",
            name: "height",
            ref: "height",
            required: "true",
            "data-error-message": "Height must be at least one character.",
            "data-min-length": "1",
            value: this.state.height,
            onChange: this.handleChange,
            onBlur: this.checkInput,
          })
        ),
        React.DOM.div(
          { className: "half" },
          React.DOM.label(
            { className: "" },
            "Weight: *",
            React.DOM.span({
              className: "error-span",
            })
          ),
          React.DOM.input({
            type: "text",
            className: "weight",
            placeholder: "Enter Weight",
            name: "weight",
            ref: "weight",
            required: "true",
            value: this.state.weight,
            "data-error-message": "Weight must be at least 2 characters.",
            "data-min-length": "2",
            onChange: this.handleChange,
            onBlur: this.checkInput,
          })
        ),
        React.DOM.div(
          { className: "camper-info-row" },
          React.DOM.div(
            { className: "half" },
            React.DOM.label(
              { className: "" },
              "GPA:",
              React.DOM.span({
                className: "error-span",
              })
            ),
            React.DOM.input({
              type: "text",
              className: "gpa",
              placeholder: "Enter GPA (optional)",
              name: "gpa",
              ref: "gpa",
              value: this.state.gpa,
              "data-error-message":
                "GPA field must not be blank - N/A is a valid response.",
              "data-min-length": "2",
              onChange: this.handleChange,
            })
          ),
          React.DOM.div(
            { className: "half" },
            React.DOM.label(
              { className: "" },
              "SAT:",
              React.DOM.span({
                className: "error-span",
              })
            ),
            React.DOM.input({
              type: "text",
              className: "sat",
              placeholder: "Enter SAT (optional)",
              name: "sat",
              ref: "sat",
              value: this.state.sat,
              "data-error-message":
                "SAT field must not be blank - N/A is a valid response.",
              "data-min-length": "2",
              onChange: this.handleChange,
            })
          )
        ),
        React.DOM.div(
          { className: "camper-info-row" },
          React.DOM.div(
            { className: "half" },
            React.DOM.span({
              className: "error-span",
            }),
            React.DOM.label,
            "Shirt Size: *",
            React.DOM.select(
              {
                className: "shirt-size",
                name: "shirt_size",
                ref: "shirt_size",
                onChange: this.handleChange,
              },
              Array.from(this.shirtSizes()).map((shirt_size) =>
                React.DOM.option(
                  { value: shirt_size, key: shirt_size },
                  shirt_size
                )
              )
            )
          )
        )
      ),
      React.DOM.button(
        {
          type: "submit",
          className: "button button-primary create",
          disabled: !this.valid(),
          style: this.props.buttonStyles,
          onClick: this.saveAndContinue,
        },
        "Next Step"
      )
    );
  }
}
