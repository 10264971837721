// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as React from "react";
import * as ReactDom from "react-dom";
import PositionDropdown from "./position_dropdown.js";
import HeightField from "./height_field.js";
import WeightField from "./weight_field.js";
import YearsInSportField from "./years_in_sport_field.js";
import YearsOfTrainingField from "./years_of_training_field.js";

export default class SportsPerformanceForm extends React.Component {
  static displayName = "SportsPerformanceForm";
  constructor(props) {
    super(props);
    let defaultPosition = this.setDefaultPosition(this.props.sport);
    this.state = {
      height: "",
      weight: "",
      position: defaultPosition,
      graduation_year: "2022",
      shirt_size: "N/A",
      injury_history: "",
      training_experience: "",
      goals: "",
      sports: "",
      years_in_sport: "",
      years_of_training: "",
      notes: "",
      custom_data: {},
    };
    this.setDefaultPosition = this.setDefaultPosition.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  setDefaultPosition = (sport) => {
    if (sport === "soccer") {
      return "Forward";
    } else if (sport === "basketball") {
      return "PG";
    } else if (sport === "lacrosse") {
      return "Attack";
    } else if (sport === "hockey") {
      return "Forward";
    } else if (sport === "volleyball") {
      return "Outside Hitter";
    } else if (sport === "baseball") {
      return "C";
    } else if (sport === "softball") {
      return "C";
    } else if (sport === "wrestling") {
      return "Wrestler";
    } else if (sport === "men soccer") {
      return "CM";
    } else if (sport === "sports-performance") {
      return "other";
    } else {
      return "All / Any";
    }
  };

  heightChanged = (newHeight) => {
    this.state.height = newHeight;
    return this.forceUpdate();
  };

  weightChanged = (newWeight) => {
    this.state.weight = newWeight;
    return this.forceUpdate();
  };

  yearsInSportChanged = (newYear) => {
    this.state.years_in_sport = newYear;
    return this.forceUpdate();
  };

  trainingYearChanged = (newYear) => {
    this.state.years_of_training = newYear;
    return this.forceUpdate();
  };

  valid = () => {
    return this.state.sports && this.state.injury_history;
  };

  validateEmail = (email) => {
    const emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  };

  validateLength = (field, requiredLength) => {
    if (field.length > requiredLength) {
      return true;
    }
  };

  validateStreetAddress = (address) => {
    return console.log("validate");
  };

  capitalizeString = (string) => {
    const capped =
      string.replace(/-/g, " ").charAt(0).toUpperCase() + string.slice(1);
    return capped;
  };
  addSportsPerformanceFields = () => {
    if (this.props.custom_registration_type == "sports-performance") {
      return (
        <React.Fragment>
          <div className={"camper-info-row"}>
            <p>Years in Sport</p>
            <YearsInSportField
              name={"years_in_sport"}
              ref={"years_in_sport"}
              value={this.state.years_in_sport}
              onChange={this.yearsInSportChanged}
            />
            <p>Years of Training</p>
            <YearsOfTrainingField
              name={"years_of_training"}
              ref={"years_of_training"}
              value={this.state.years_of_training}
              onChange={this.trainingYearChanged}
            />
          </div>
          <div className={"camper-info-row"}>
            <div className="half">
              <label>Injury History:</label>
              <span className="error-span"></span>
              <textarea
                rows={{ 5: 5 }}
                name="injury_history"
                ref="injury_history"
                value={this.state.injury_history}
                onChange={this.handleChange}
                placeholder="Your injury history"
              ></textarea>
            </div>
            <div className="half">
              <label>Training Experience:</label>
              <span className="error-span"></span>
              <textarea
                rows={{ 5: 5 }}
                name="training_experience"
                ref="training_experience"
                value={this.state.training_experience}
                onChange={this.handleChange}
                placeholder="Your previous training experience"
              ></textarea>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };
  addGoalsNotesFields = () => {
    if (this.props.custom_registration_type == "sports-performance") {
      return (
        <React.Fragment>
          <div className="half">
            <label>Goals:</label>
            <span className="error-span"></span>
            <textarea
              rows={{ 5: 5 }}
              name="goals"
              ref="goals"
              value={this.state.goals}
              onChange={this.handleChange}
              placeholder="What are your goals?"
            ></textarea>
          </div>
          <div className="half">
            <label>Notes:</label>
            <span className="error-span"></span>
            <textarea
              rows={{ 5: 5 }}
              name="notes"
              ref="notes"
              value={this.state.notes}
              onChange={this.handleChange}
              placeholder="Anything else you'd like for us to know"
            ></textarea>
          </div>
        </React.Fragment>
      );
    }
  };

  setUpGradYearOptions = () => {
    let gradYears = [
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "N/A",
    ];
    let options = [];
    for (let gradYear of gradYears) {
      options.push(
        <option value={gradYear} key={gradYear}>
          {gradYear}
        </option>
      );
    }
    return options;
  };

  setUpShirtSizeOptions = () => {
    let shirtSizes = [
      "N/A",
      "Y-Small",
      "Y-Medium",
      "Y-Large",
      "Small",
      "Medium",
      "Large",
      "X-Large",
    ];
    let shirtOptions = [];
    for (let shirtSize of shirtSizes) {
      shirtOptions.push(
        <option value={shirtSize} key={shirtSize}>
          {shirtSize}
        </option>
      );
    }
    return shirtOptions;
  };

  addShirtField = () => {
    if (this.props.collect_tee_shirt_size) {
      return (
        <div className="camper-info-row">
          <div className="half">
            <p>Shirt Size (if applicable): *</p>
            <span className="error-span"></span>
            <select
              onChange={this.handleChange}
              name={"shirt_size"}
              className={"shirt-size field"}
              data-name={"shirt_size"}
            >
              {this.setUpShirtSizeOptions()}
            </select>
          </div>
        </div>
      );
    }
  };

  checkInput = (e) => {
    const itemClass = e.target.className;
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    return this.showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      const errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      return $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      return $(errorSpan).html("");
    }
  };

  positionChange = (positionValue) => {
    const fieldName = "position";
    return this.setState({ [fieldName]: positionValue });
  };

  handleChange = (e) => {
    const { name } = e.target;
    return this.setState({ [name]: e.target.value });
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    const data = {
      height: this.state.height,
      weight: this.state.weight + "lbs",
      position: this.state.position,
      club_team: this.state.club_team,
      gpa: this.state.gpa,
      sat: this.state.sat,
      act_score: this.state.act_score,
      graduation_year: this.state.graduation_year,
      roommate_preference: this.state.roommate_preference,
      peers_attending: this.state.peers_attending,
      shirt_size: this.state.shirt_size,
      goals: this.state.goals,
      injury_history: this.state.injury_history,
      training_experience: this.state.training_experience,
      sports: this.state.sports,
      years_in_sport: this.state.years_in_sport,
      years_of_training: this.state.years_of_training,
    };
    this.props.saveValues(data);
    return this.props.nextStep();
  };

  render() {
    return (
      <form className={"new-step-form"}>
        <h1 className={"form-heading"}>
          {"Step 3. Sports Performance Information"}
        </h1>
        <div className={"camper-info-row"}>
          <div className={"half"}>
            <span className={"error-span"}></span>
            <label>{"Sports: *"}</label>
            <input
              type={"text"}
              className={"club-team full-field"}
              placeholder={
                "List sports seperated by a , (ex: soccer, hockey, basketball, baseball)"
              }
              name={"sports"}
              ref={"sports"}
              data-error-message={
                "Sports must be at least one character - N/A is a valid response."
              }
              data-min-length={"1"}
              value={this.state.sports}
              onChange={this.handleChange}
              onBlur={this.checkInput}
            />
          </div>
        </div>
        <div className={"camper-info-row"}>
          {this.addSportsPerformanceFields()}
        </div>
        <div className={"camper-info-row"}>{this.addGoalsNotesFields()}</div>
        <div className={"camper-info-row"}>{this.addShirtField()}</div>
        <div className={"camper-info-row"}>
          <button
            type={"submit"}
            className={"button button-primary create"}
            disabled={!this.valid()}
            style={this.props.buttonStyles}
            onClick={this.saveAndContinue}
          >
            {"Next Step"}
          </button>
        </div>
      </form>
    );
  }
}
