var React = require("react")
var ReactDom = require("react-dom")

class PricePointVisualSelection extends React.Component {
  static displayName = 'PricePointVisualSelection';

  state = {
    
  };

  onPricePointChange = (event) => {
    this.setState({price_point: event.target.value});
    return this.props.onChange(event.target.value);
  };

  setAvailablePricePoints = (pricePointArray) => {};

  render() {
    return React.DOM.select({
        className: 'price_point',
        id: 'price_point_select',
        name: 'price_point',
        ref: 'price_point',
        value: this.state.price_point,
        onChange: this.onPricePointChange
      },
        (() => {
        const result = [];
        for (price_point of Array.from(this.props.price_points)){
          result.push(React.DOM.option({key: price_point.id, value: price_point.id}, price_point.title + " - $" + (price_point.price_cents/100).toString()));
        }
        return result;
      })()
    )
  }
}

module.exports = PricePointVisualSelection;