import * as React from "react";
import * as ReactDom from "react-dom";

export default class GenderField extends React.Component {
  static displayName = "GenderField";

  constructor(props) {
    super(props);
    this.state = { gender: this.props.gender };
  }

  setUpOptions = () => {
    const results = [];
    for (let gender of Array.from(this.genderOptionsArray())) {
      results.push(
        <option value={gender} key={gender}>
          {gender}
        </option>
      );
    }
    return results;
  };

  onFieldChange = (event) => {
    return this.props.onChange(event.target.value);
  };

  genderOptionsArray = () => {
    return ["Male", "Female", "Non Binary", "Prefer Not to Say"];
  };
  render() {
    return (
      <div className="gender-field-container">
        <span className="gender-label">{"Gender"}</span>
        <select
          className={"gender-dropdown third-field"}
          name={"gender"}
          ref={"gender"}
          onChange={this.onFieldChange}
          value={this.props.gender}
        >
          {this.setUpOptions()}
        </select>
      </div>
    );
  }
}
