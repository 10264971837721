// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as React from "react";
import * as ReactDom from "react-dom";

export default class LiabilityWaiverForm extends React.Component {
  static displayName = "LiabilityWaiverForm";

  state = {
    checked: false,
    liability_waiver: false,
    liability_initials: "",
    liability_waiver_signature: "",
    signature_image: null,
  };

  valid = () => {
    if (this.state.checked === true && this.state.liability_waiver_signature) {
      return true;
    }
  };

  validateLength = (field, requiredLength) => {
    if (field.length > requiredLength) {
      return true;
    }
  };

  handleChange = (e) => {
    const { name } = e.target;
    return this.setState({ [name]: e.target.value });
  };

  handleChecked = (e) => {
    this.setState({ checked: e.target.checked });
    return this.setState({ liability_waiver: e.target.liability_waiver });
  };

  componentDidUpdate = (e) => {
    console.log(e);
  };

  setTodaysDate = () => {
    let options = { year: "numeric", month: "long", day: "numeric" };
    return new Date().toLocaleDateString("en-US", options);
  };

  handleCamperSubmit = (e) => {
    e.preventDefault();
    const newData = {
      liability_waiver: this.state.checked,
      liability_waiver_signature: this.state.liability_waiver_signature,
    };
    this.props.saveValues(newData);
    return this.props.nextStep();
  };

  render() {
    return (
      <form className={"new-step-form"}>
        <div className={"coolio"}>
          <h1>{"Step 4. Event Liability Waiver"}</h1>
        </div>
        <div className={"camper-info-row signature-info-box"}>
          <div className={"waiver-text"}>{this.props.waiver}</div>
        </div>
        <div className={"camper-info-row signature-info-box"}>
          <span className={"digi-sig"}>
            {"Digital Signature - I have read and agreed to the above waiver "}
          </span>
          <input
            type={"checkbox"}
            defaultChecked={this.state.checked}
            name={"liability_waiver"}
            ref={"liability_waiver"}
            value={this.state.liability_waiver}
            onChange={this.handleChecked}
          />
        </div>
        <div className={"camper-info-row signature-info-box"}>
          <div className={"lw-signature-label"}>
            {"Signed by " +
              `${this.props.signature_name}` +
              " on " +
              `${this.setTodaysDate()}`}
          </div>
          <div className={"camper-info-row lw-signature-container"}>
            <div className="signature-row">
              <i className="fas fa-pen-nib pen-polygon"></i>
              <input
                type={"text"}
                className="signature-input"
                name={"liability_waiver_signature"}
                ref={"liability_waiver_signature"}
                value={this.state.liability_waiver_signature}
                onChange={this.handleChange}
              />
            </div>
            <div className="signature-row">
              <span className="enter-your-name">
                {"Enter " + `${this.props.signature_name}` + " to sign"}
              </span>
            </div>
          </div>
        </div>
        <button
          type={"submit"}
          className={"button button-primary create"}
          disabled={!this.valid()}
          style={this.props.buttonStyles}
          onClick={this.handleCamperSubmit}
        >
          Next Step
        </button>
      </form>
    );
  }
}
