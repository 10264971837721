import * as React from "react";
import * as ReactDom from "react-dom";

export default class HeightField extends React.Component {
  static displayName = "HeightField";

  constructor(props) {
    super(props);
    this.state = {
      feet: "3",
      inches: "0",
      height: "",
    };
  }

  onFeetChange = (event) => {
    const { name } = event.target;
    return this.setState(
      {
        [name]: event.target.value,
        height: `${event.target.value}ft ${this.state.inches}in`,
      },
      function () {
        return this.props.onChange(this.state.height);
      }
    );
  };

  onInchesChange = (event) => {
    const { name } = event.target;
    return this.setState(
      {
        [name]: event.target.value,
        height: `${this.state.feet}ft ${event.target.value}in`,
      },
      function () {
        return this.props.onChange(this.state.height);
      }
    );
  };

  setUpFeetOptions = () => {
    let results = [];
    for (let footMark = 1; footMark <= 8; footMark++) {
      results.push(
        <option value={footMark} key={footMark}>
          {`${footMark} ft`}
        </option>
      );
    }
    return results;
  };

  setUpInchesOptions = () => {
    let results = [];
    for (let inchMark = 0; inchMark <= 12; inchMark++) {
      results.push(
        <option value={inchMark} key={inchMark}>
          {`${inchMark} in`}
        </option>
      );
    }
    return results;
  };

  render() {
    return (
      <div className="height-select-container">
        <span className="error-span"></span>
        <label className="height">Height</label>
        <div className="camper-info-row">
          <select
            className="third-field"
            name="feet"
            onChange={this.onFeetChange}
            value={this.state.feet}
          >
            {this.setUpFeetOptions()}
          </select>

          <select
            className="third-field"
            name="inches"
            onChange={this.onInchesChange}
            value={this.state.inches}
          >
            {this.setUpInchesOptions()}
          </select>
        </div>
      </div>
    );
  }
}
