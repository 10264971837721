import * as React from "react";
import * as ReactDom from "react-dom";

import Cleave from "cleave.js/dist/cleave-react";

export default class ExciteMedicalForm extends React.Component {
  static displayName = "ExciteMedicalForm";

  state = {
    physician: "",
    physician_contact_number: "",
    insurer: "",
    insurance_policy_holder: "",
    group_number: "",
    medications: "",
    conditions: "",
    dietary_restrictions: "",
    physical_restrictions: "",
    allergies: "",
    diabetes: false,
    asthma: false,
    special_needs: false,
  };

  componentDidMount() {
    // $("#phone-mask").mask("(999) 999-9999");
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  handlePhoneChange = (e) => {
    const { name } = e.target;
    if (name == "guardian_contact_number") {
      return this.setState({
        [name]: this.state.guardian_contact_country_code + " " + e.target.value,
      });
    } else if (name == "emergency_contact_number") {
      return this.setState({
        [name]:
          this.state.emergency_contact_country_code + " " + e.target.value,
      });
    } else {
      return this.setState({ [name]: e.target.value });
    }
  };

  valid = () => {
    return true;
  };

  validateLength = (field, requiredLength) => {
    if (field.length > requiredLength) {
      return true;
    }
  };

  handleChange = (e) => {
    const { name } = e.target;
    return this.setState({ [name]: e.target.value });
  };

  handleChecked = (e) => {
    const { name } = e.target;
    console.log(name);
    return this.setState({ [name]: e.target.checked });
  };

  checkInput = (e) => {
    const itemClass = e.target.className;
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    return this.showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      const errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      return $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      return $(errorSpan).html("");
    }
  };

  handleCamperSubmit = (e) => {
    e.preventDefault();
    let newData = {
      allergies: this.state.allergies,
      medications: this.state.medications,
      physician: "Not collected",
      physician_contact_number: "Not collected",
      insurer: "Not collected",
      insurance_policy_holder: "Not collected",
      group_number: "Not collected",
      asthma: this.state.asthma,
      conditions: this.state.condition,
      diabetes: this.state.diabetes,
      dietary_restrictions: this.state.dietary_restrictions,
      physical_restrictions: this.state.physical_restrictions,
      special_needs: this.state.special_needs,
    };
    this.props.saveValues(newData);
    return this.props.nextStep();
  };

  render() {
    return (
      <form className={"new-step-form"}>
        <div className={"place"}>
          <h1 className={"form-heading"}>
            {"Step" + `${this.props.stepLabel}` + " Medical Information"}
          </h1>
          <div className={"health-container camper-info-row"}>
            <span className={"up-size"}>{"Health Overview:"}</span>
            <span className={"error-span"}></span>
            <div className={"camper-info-row"}>
              <textarea
                rows={{ 5: 5 }}
                className={"meds full-field"}
                placeholder={"Please list any Medications..."}
                name={"medications"}
                ref={"medications"}
                onChange={this.handleChange}
                onBlur={this.checkInput}
                value={this.state.medications}
              ></textarea>
              <textarea
                rows={{ 5: 5 }}
                className={"conds full-field"}
                placeholder={"Please list any Medical Conditions - etc...."}
                name={"conditions"}
                ref={"conditions"}
                onChange={this.handleChange}
                onBlur={this.checkInput}
                value={this.state.conditions}
              ></textarea>
            </div>
            <div className={"camper-info-row"}>
              <textarea
                rows={{ 5: 5 }}
                className={"allergiess full-field"}
                placeholder={"Please list any Allergies...."}
                name={"allergies"}
                ref={"allergies"}
                onChange={this.handleChange}
                onBlur={this.checkInput}
                value={this.state.allergies}
              ></textarea>
            </div>
            <div className={"camper-info-row"}>
              <textarea
                rows={{ 5: 5 }}
                className={"dietary-restrictions full-field"}
                placeholder={"Please list any Dietary Restrictions...."}
                name={"dietary_restrictions"}
                ref={"dietary_restrictions"}
                onChange={this.handleChange}
                onBlur={this.checkInput}
                value={this.state.dietary_restrictions}
              ></textarea>
            </div>
            <div className={"camper-info-row"}>
              <textarea
                rows={{ 5: 5 }}
                className={"dietary-restrictions full-field"}
                placeholder={"Please list any Phyiscal Restrictions...."}
                name={"physical_restrictions"}
                ref={"physical_restrictions"}
                onChange={this.handleChange}
                onBlur={this.checkInput}
                value={this.state.physical_restrictions}
              ></textarea>
            </div>
            <div className={"camper-info-row"}>
              <div className={"check-split third-field"}>
                <span
                  htmlFor={"special_needs"}
                  className={"check-span up-size"}
                >
                  {"Special Needs"}
                </span>
                <input
                  type={"checkbox"}
                  className={"special-needs"}
                  name={"special_needs"}
                  ref={"special_needs"}
                  value={this.state.special_needs}
                  onChange={this.handleChecked}
                />
              </div>
            </div>
            <div className={"camper-info-row"}>
              <div className={"check-split third-field"}>
                <span htmlFor={"diabetes"} className={"check-span up-size"}>
                  {"Diabetes"}
                </span>
                <input
                  type={"checkbox"}
                  className={"diabetes"}
                  name={"diabetes"}
                  ref={"diabetes"}
                  value={this.state.diabetes}
                  onChange={this.handleChecked}
                />
              </div>
              <div className={"check-split third-field"}>
                <span htmlFor={"asthma"} className={"check-span up-size"}>
                  {"Asthma"}
                </span>
                <input
                  type={"checkbox"}
                  className={"asthma"}
                  name={"asthma"}
                  ref={"asthma"}
                  value={this.state.asthma}
                  onChange={this.handleChecked}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={"camper-info-row"}>
          <button
            type={"submit"}
            className={"button button-primary create"}
            disabled={!this.valid()}
            style={this.props.buttonStyles}
            onClick={this.handleCamperSubmit}
          >
            {"Next Step"}
          </button>
        </div>
      </form>
    );
  }
}
