$(function(){
  var selectMe = $('#registration-refund').selectize({
    plugins: ['remove_button'],
    valueField: 'id',
    searchField: ['first_name', 'last_name', 'email'],
    options: [],
    maxItems: 1,
    create: false,
    // optgroups: [
    //   {value: 'camp', label: 'Camp'},
    //   {value: 'user', label: 'User'},
    //   {value: 'camper', label: 'Camper'}
    // ],
    // optgroupfields: 'series',
    onItemAdd: function(value, $item){
      var splitItem = value.split('-');
      var itemId = splitItem[0];
      var itemType = splitItem[1];
      $.ajax({
        url: '/admin/registrations/' + itemType + '_registrations.json',
        type: 'GET',
        dataType: 'json',
        data: {
          id: itemId
        },
        error: function(){
          callback();
        },
        success: function(res){
          console.log(res);
          if (res.length > 0){
            for (var i = 0; i < res.length; i++) {
              $('<div data-id="' + res[i][0] + '" class="registration-item">' +
                '<i class="fa fa-calendar-check-o"></i>' +
                '<span class="reg-id">ID #' + res[i][0] + '</span>' +
                '<p class="reg-user"><span>Registering User:</span><br>' + res[i][1] + '</p>' +
                '<p class="reg-camper"><span>Camper:</span><br>' + res[i][4] + '</p>' +
                '<p class="reg-date">' + res[i][3] + '</p>' +
                '<p class="reg-camp-title"><span>Camp:</span><br>' + res[i][2] + '</p>' +
                '</div>').appendTo('.registration-link-outlay');
            }
          }
        }
      });
    },
    onItemRemove: function(value){
      $('.registration-link-outlay').empty();
    },
    render: {
      item: function(item, escape) {
        if (item.first_name != null){
          return '<div class="item" series="user" data-item-type="user">' + escape(item.first_name) + ' ' + escape(item.last_name) + " - " + escape(item.email) + '</div>';
        }
      },
      option: function(item, escape){
        if (item.first_name != null){
          return '<div class="option" series="user" data-item-type="user">' + '<span class="name">' + escape(item.first_name) + ' ' + escape(item.last_name) + ' </span>' + " - " + escape(item.email) + '</div>';
        }
      }
    },
    load: function(query, callback) {
      if (!query.length) return callback();
      $.ajax({
        url: '/admin/registrations/search_endpoint.json',
        type: 'GET',
        dataType: 'json',
        data: {
          q: query,
          page_limit: 10,
        },
        error: function() {
          callback();
        },
        success: function(res) {
          callback(res);
        }
      });
    }
  });
});

$(document).on('click', '.registration-item', function(evt){
  var regId = $(this).data('id');
  location.href = ('/admin/registrations/' + regId);
});