var RoommatePreferenceField = React.createClass({
  render(){
    return React.DOM.div(
      {className: 'half'},
      React.DOM.label,
        'Roommate Preferences:',
        React.DOM.span({
          className: 'error-span'}),
      React.DOM.textarea({
        rows: {5: 5},
        placeholder: 'Please list any roommate preferences...',
        name: 'roommate_preference',
        ref: 'roommate_preference',
        value: this.state.roommate_preference,
        onChange: this.handleChange
      })
    )
  }
});
module.exports = RoommatePreferenceField;