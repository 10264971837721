import * as React from "react";
import * as ReactDom from "react-dom";

export default class YearsInSportField extends React.Component {
  static displayName = "YearsInSportField";

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    const { name } = event.target;
    return this.props.onChange(`${event.target.value} years`);
  };

  setUpOptions = () => {
    let results = [];
    for (let years = 0; years <= 20; years++) {
      results.push(
        <option value={years} key={years}>
          {`${years} years`}
        </option>
      );
    }
    return results;
  };

  render() {
    return (
      <div className="year-select-container">
        <span className="error-span"></span>
        <label className="years-in-sport">Years in Sport</label>
        <div className="camper-info-row">
          <select
            className="third-field"
            name="years_in_sport"
            onChange={this.handleChange}
            value={this.props.years_in_sport}
          >
            {this.setUpOptions()}
          </select>
        </div>
      </div>
    );
  }
}
