// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import * as React from 'react';
import * as ReactDom from 'react-dom';

import Cleave from "cleave.js/dist/cleave-react";

export default class PartialRefundForm extends React.Component {
  static displayName = 'PartialRefundForm';

  constructor(props){
    super(props)
    this.state = {
      amount_to_refund: 0,
      emails: '',
      token: ''
    };
  }

  componentDidMount() {
    const csrf = $('meta[name=csrf-token]').attr('content');
    return this.setState({token: csrf});
  }

  componentWillUnmount() {
    return this.setState({amount_to_refund: '0'});
  }

  valid = () => {
    return this.validateRefundAmount(this.state.amount_to_refund);
  };

  validateRefundAmount = (field) => {
    return true
    // if ((field.length > 1) && (field.length < 4)) { return true; }
  };

  formatAmount = (amount) => {
    let dollars = (amount / 100)
    return dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  handleChange = (e) => {
    const name = $(e.target).data('name');
    return this.setState({[name]: e.target.rawValue});
  };

  handleSubmit = (e) => {
    const form = document.getElementById('partial-refund');
    form.submit();
  };

  render(){
    return(
      <form className='refund-form' id='partial-refund' method='POST' action={`/admin/registrations/${this.props.registration_id}/partial_payment_refund`}>
        <div className="heading">
          <h1 className="form-heading">
            Confirm Partial Refund
          </h1>
        </div>
          <div className="refund-recap">
            <div className="refund-info-box">
              <p className="participant-info">Participant:</p>
              <p className="participant-name">{this.props.camper_name}</p>
              <p className="field-description">Primary Contact:</p>
              <p className="field-content">{this.props.registrant}</p>
              <p className="field-bullet">{this.props.registrant_email}</p>
              <p className="field-bullet">{this.props.registrant_phone_number}</p>
            </div>
            <div className="refund-info-box">
              <p className="field-description">Event Information:</p>
              <p className="field-content">{this.props.camp_title}</p>
              <p className="field-bullet">{this.props.date_range}</p>
            </div>
            <div className="refund-info-box">
              <p className="field-description">Amount Paid:</p>
              <p className="field-content">{this.formatAmount(this.props.amount_paid)}</p>
            </div>
            <div className="refund-info-box">
              <p className="field-description">Amount To Refund:</p>
              <Cleave
                id="amount_to_refund"
                type="text"
                min="0"
                max={this.props.amount_paid}
                className={"field two-thirds-field"}
                name="refund[amount_to_refund]"
                data-name="amount_to_refund"
                placeholder={"Amount to Refund ($)"}
                options={{ prefix: '$', numeral: true, rawValueTrimPrefix: true, numericOnly: true, noImmediatePrefix: false, numeralPositiveOnly: true }}
                value={this.state.amount_to_refund}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="refund-row">
            <input type='hidden' key='utf8' name='utf8' value="✓" />
            <input type='hidden' key='authenticity_token' name='authenticity_token' value={this.state.token} />
            <input type='hidden' name='refund[transaction_id]' value={this.props.transaction_id} />
            <input type='hidden' name='refund[registration_id]' value={this.props.registration_id} />
          </div>
          <div className="button-row">
            <button type='submit'
                    className='button button-primary refund-button'
                    style={this.props.buttonStyles}
                    onClick={this.handleSubmit}>
              Confirm Partial Refund
            </button>
          </div>
      </form>
    )
  }
}