import * as React from "react";
import * as ReactDom from "react-dom";
import Select from "react-select";

const colorOptions = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export default class SportsSelect extends React.Component {
  static displayName = "SportsSelect";

  render() {
    return (
      <Select
        defaultValue={[colorOptions[2], colorOptions[3]]}
        isMulti
        name="colors"
        options={colorOptions}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    );
  }
}
