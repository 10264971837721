import * as React from 'react';
import * as ReactDom from 'react-dom';
import * as axios from 'axios';


const tokenDom = document.querySelector("meta[name=csrf-token]")
if (tokenDom) {
   const csrfToken = tokenDom.content;
   axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
}

export class WaitlistButton extends React.Component {
  static displayName = 'WaitlistButton';

  constructor(props){
    super(props);
    this.state = { 
      waitlisted: true,
      waitListEntryCreated: false,
      waitListEntryId: null,
      waitlistMessage: "Join Waitlist",
      pricePointId: this.props.price_point_id
     };
  };

  componentDidMount() {
    if (this.props.waitlist_price_point_ids.includes(this.state.pricePointId)){
      this.setState({waitlistMessage: "Waitlisted", waitlisted: true });
    }
  }

  updateAdminWaitlist = () => {
    const waitlistEntry = {
      price_point_id: this.state.pricePointId,
    };
    axios.post("/waitlist_entries.json", { waitlist_entry: waitlistEntry })
      .then(res => {
        let waitlistId = res.data['id']
        this.setState({waitListEntryCreated: true, waitListEntryId: waitlistId})
      })
  };

  onWaitlistButtonChange = (event) => {
    if (this.state.waitlisted == false){
      this.setState({waitlistMessage: "Waitlisted", waitlisted: true });
      if (this.state.waitListEntryCreated != true){
        this.updateAdminWaitlist();
      }
    } else {
      this.setState({waitlistMessage: "Add to Waitlist", waitlisted: false});
      if (this.state.waitListEntryCreated == true){
        console.log('delete waitlist entry here')
      }
    }
  };

  render() {
    return(
      <a className={'wait-btn ' + `${this.state.waitlisted}`} onClick={ this.onWaitlistButtonChange }>
        {this.state.waitlistMessage}
      </a>)
  };
}