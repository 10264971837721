function LoadingPanel(props) {
  if (!props.loading_payment) {
    return null;
  }

  return (
    <div className="loading-panel">
      <h3 className="lp-heading">Sending Payment information</h3>
      <div id="loading-spinner"></div>
      <br />
      <p className="lp-content">Please do not reload the page.</p>
    </div>
  );
}

import * as React from "react";
import * as ReactDom from "react-dom";

import * as currency from "currency.js";
import { Spinner } from "spin.js";
import * as Toastify from "toastify-js";

import { PricePointSelect } from "./price_point_select.js";

let stripe = Stripe(process.env.STRIPE_PUBLISHABLE); // process.env.STRIPE_PUBLISHABLE
let elements = stripe.elements();

let card = elements.create("card", {
  style: {
    base: {
      fontFamily: "Arial, sans-serif",
      fontSize: "30px",
      color: "#C1C7CD",
    },
    invalid: {
      color: "red",
    },
  },
});

card.addEventListener("change", function (event) {
  let displayError = document.getElementById("card-errors");
  if (event.error) {
    displayError.textContent = event.error.message;
  } else {
    displayError.textContent = "";
  }
});

// Mount the UI card component into the `card-element` <div>

export default class PaymentForm extends React.Component {
  static displayName = "PaymentForm";
  constructor(props) {
    super(props);
    let altButtonStyles = {
      color: this.props.buttonStyles.backgroundColor,
      backgroundColor: this.props.buttonStyles.color,
      border: `2px solid ${this.props.buttonStyles.backgroundColor}`,
    };

    this.state = {
      current_camper: "",
      liability_waiver: false,
      shirt_size: "",
      custom_data: {},
      graduation_year: "",
      notes: "",
      liability_waiver_signature: "",
      disabled: false,
      discount_code: "",
      discounted: false,
      loading_payment: false,
      price_point_id: this.props.primary_price_point.id,
      alt_button_styles: altButtonStyles,
    };
  }

  monthName = () => {
    const monthNumberStartingFromZero = new Date().getMonth();
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][monthNumberStartingFromZero].toString();
  };

  currentMonthNumber = () => {
    const monthNumberStartingFromZero = new Date().getMonth();
    return [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ][monthNumberStartingFromZero].toString();
  };

  componentDidMount() {
    card.mount("#card-element");
    let fakeId = this.props.camper_identifier;
    let campers = this.props.campers;
    let player = campers[fakeId];

    let shirtSize = "";
    let customData = {};
    let gradYear = "";
    let notes = "";
    let liabilityWaiverSignature = "";

    if (player?.shirt_size) {
      shirtSize = player["shirt_size"];
    }
    if (player?.graduation_year) {
      gradYear = player["graduation_year"];
    }
    if (player?.custom_data) {
      customData = player["custom_data"];
    }
    if (player?.notes) {
      notes = player["notes"];
    }
    if (player?.liability_waiver_signature) {
      liabilityWaiverSignature = player["liability_waiver_signature"];
    }
    this.setCartFields(this.props.primary_price_point, false);

    this.setState({
      liability_waiver: true,
      shirt_size: shirtSize,
      custom_data: customData,
      graduation_year: gradYear,
      notes: notes,
      liability_waiver_signature: liabilityWaiverSignature,
    });

    $.post("/campers", { camper: player }, (data) => {
      return this.setState({ current_camper: data.id });
    });
  }

  componentDidUpdate() {
    let target = document.getElementById("loading-spinner");
    if (target != null) {
      let pinWheel = new Spinner({ color: "#fff", lines: 10, speed: 1 }).spin(
        target
      );
    }
  }

  componentWillUnmount() {
    this.setState({ current_camper: "", liability_waiver: null });
  }

  camperPost = (player) => {
    $.post("/campers", { camper: player }, (data) => {
      return data.id;
    });
  };

  validateDiscountCodeLength = (field) => {
    if (field.length > 5) {
      return true;
    }
  };

  validDiscountCode = () => {
    return this.validateDiscountCodeLength(this.state.discount_code);
  };

  checkInput = (e) => {
    const itemClass = e.target.className;
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    this.showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  setCartFields = (pricePoint, discounted) => {
    let subTotalCents = currency(pricePoint.price_cents, { fromCents: true });

    let discountCents = currency(this.state.discount_amount, {
      fromCents: true,
    });
    let amountOff;

    let subTotalField = document.getElementById("subtotal");
    let totalField = document.getElementById("total-value");

    if (discounted) {
      let discountRow = document.getElementById("discount-items");
      let discount = document.getElementById("discount");
      let discountTag = document.getElementById("discount-tag");
      let cancelDiscount = document.getElementById("remove-discount-code");
      discount.classList.add("price-value");
      discountRow.classList.remove("hidden");
      cancelDiscount.classList.remove("hidden");

      if (this.state.discount_type == "percentage") {
        let percentOff = discountCents.divide(100);
        if (percentOff > 1) {
          amountOff = currency(0, { fromCents: true });
          discount.innerText = "- " + amountOff.format();
          Toastify({
            text: "Discount Invalid - cannot apply discount for more than 100%",
            duration: 3000,
            close: false,
            position: "right", // `left`, `center` or `right`
            backgroundColor: "#dc3545",
            className: "discount-failure",
            stopOnFocus: true, // Prevents dismissing of toast on hover
          }).showToast();
        } else {
          amountOff = subTotalCents.multiply(percentOff);
          discount.innerText = "- " + amountOff.format();
          discountTag.classList.remove("hidden");
          discountTag.innerText = this.state.discount_code;
          Toastify({
            text: "Discount Successful!",
            className: "discount-success",
            duration: 3000,
            newWindow: false,
            close: false,
            gravity: "top", // `left`, `center` or `right`
            position: "right", // `left`, `center` or `right`
            backgroundColor: "#3ecf8e",
            stopOnFocus: true,
          }).showToast();
        }
      } else {
        if (discountCents.dollars() > subTotalCents.dollars()) {
          amountOff = currency(0, { fromCents: true });
          discount.innerText = "- " + amountOff.format();
          Toastify({
            text: "Discount Invalid - cannot apply discount for more than price",
            duration: 3000,
            close: false,
            position: "right", // `left`, `center` or `right`
            backgroundColor: "#dc3545",
            className: "discount-failure",
            stopOnFocus: true, // Prevents dismissing of toast on hover
          }).showToast();
        } else {
          amountOff = discountCents;
          discount.innerText = "- " + discountCents.format();
          discountTag.classList.remove("hidden");
          discountTag.innerText = this.state.discount_code;
          Toastify({
            text: "Discount Successful!",
            duration: 3000,
            newWindow: false,
            close: false,
            className: "discount-success",
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            backgroundColor: "#3ecf8e",
            stopOnFocus: true,
          }).showToast();
        }
      }
    }

    subTotalField.classList.add("price-value");
    subTotalField.innerText = subTotalCents.format();

    let newSubTotalCents = subTotalCents.subtract(amountOff);
    let feeCents;

    if (this.props.fee_breakout) {
      let firewoodFeeCents = newSubTotalCents.multiply(
        this.props.fee_percentage
      );
      let stripeFixedCents = currency(30, {
        fromCents: true,
      });
      let updatedSubTotalCents = newSubTotalCents
        .add(firewoodFeeCents)
        .add(stripeFixedCents);
      let newTotalWithStripeFee = updatedSubTotalCents.divide(0.971);
      feeCents = newTotalWithStripeFee.subtract(newSubTotalCents);
      let feeRow = document.getElementById("fee-row");
      let feeTotal = document.getElementById("fee");
      subTotalCents = newTotalWithStripeFee;
      // feeRow.classList.remove('hidden')
      feeTotal.classList.add("price-value");
      feeTotal.innerText = feeCents.format();
    } else {
      subTotalCents = newSubTotalCents;
      feeCents = currency(0, { fromCents: true });
    }

    let newTotalCents = subTotalCents || currency(0, { fromCents: true });
    totalField.innerText = newTotalCents.format();
  };

  pricePointDropdownChanged = (newId) => {
    this.state.price_point_id = newId;
    let pricePoints = JSON.parse(this.props.price_points);
    let pricePoint = pricePoints.find((obj) => obj.id == newId);
    this.setCartFields(pricePoint, this.state.discounted);
    return this.forceUpdate();
  };

  handleChange = (e) => {
    const name = $(e.target).data("name");
    this.setState({ [name]: e.target.value });
  };

  createToken = () => {
    function stripeTokenHandler(token) {
      let form = document.getElementById("new_registration");
      let hiddenInput = document.createElement("input");
      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("name", "payment[stripeToken]");
      hiddenInput.setAttribute("value", token.id);
      form.appendChild(hiddenInput);
      form.submit();
    }

    stripe.createToken(card).then(function (result) {
      if (result.error) {
        // Inform the user if there was an error
        var errorElement = document.getElementById("card-errors");
        errorElement.textContent = result.error.message;
        this.setState({ loading_payment: false, disabled: false });
      } else {
        stripeTokenHandler(result.token);
      }
    });
  };

  resetDiscountCode = () => {
    Toastify({
      text: "Discount Removed",
      duration: 3000,
      close: false,
      position: "right", // `left`, `center` or `right`
      backgroundColor: "#dc3545",
      className: "discount-failure",
      stopOnFocus: true, // Prevents dismissing of toast on hover
    }).showToast();

    let discountRow = document.getElementById("discount-items");
    let discountTag = document.getElementById("discount-tag");
    let cancelDiscount = document.getElementById("remove-discount-code");

    cancelDiscount.classList.add("hidden");
    discountTag.classList.add("hidden");
    discountRow.classList.add("hidden");

    let pricePoints = JSON.parse(this.props.price_points);
    let pricePoint = pricePoints.find(
      (obj) => obj.id == this.state.price_point_id
    );
    this.setCartFields(pricePoint, false);
    this.setState({ discounted: false, discount_code: "" });
    return this.forceUpdate();
  };

  handleDiscountCode = (e) => {
    let code = $(".discount-box").val();
    if (this.state.discounted == false) {
      let discountRow = document.getElementById("discount-items");
      let discount = document.getElementById("discount");
      $.post(
        "/verify_discount",
        { discount: { camp_id: this.props.camp_id, code } },
        (data) => {
          if (data.status === 200) {
            let discountData = data.discount_data;
            if (discountData) {
              let discountType = discountData.discount_type;
              let discountAmountCents = discountData.amount_cents;
              this.setState({
                discounted: true,
                discount_amount: discountAmountCents,
                discount_type: discountType,
              });
              let pricePoints = JSON.parse(this.props.price_points);
              let pricePoint = pricePoints.find(
                (obj) => obj.id == this.state.price_point_id
              );
              this.setCartFields(pricePoint, true);
            }
          } else {
            this.setState({
              discounted: false,
              discount_amount: 0,
              discount_type: null,
            });
            discount.classList.add("price-value");
            discountRow.classList.remove("hidden");
            let nonDiscountAmountOff = currency(0, { fromCents: true });
            discount.innerText = "- " + nonDiscountAmountOff.format();

            Toastify({
              text: "Discount Invalid",
              duration: 3000,
              newWindow: false,
              close: false,
              className: "discount-failure",
              gravity: "top", // `top` or `bottom`
              position: "right", // `left`, `center` or `right`
              backgroundColor: "#dc3545",
              stopOnFocus: true,
            }).showToast();
          }
        }
      );
    }
  };

  handleSubmit = (e) => {
    let form = document.getElementById("new_registration");
    this.setState({ loading_payment: true, disabled: true });
    e.preventDefault();
    this.createToken();
  };

  render() {
    return (
      <form
        className={"new-step-form"}
        width={"100%"}
        id={"new_registration"}
        method={"POST"}
        action={`/camps/${this.props.camp_id}/registrations`}
      >
        <div className={"choppa"}>
          <h1 className={"form-heading"}>{"Step 5. Payment"}</h1>
        </div>
        <div className={"purchase-recap price-point-options"}>
          <PricePointSelect
            price_points={JSON.parse(this.props.price_points)}
            primary_price_point={this.props.primary_price_point}
            onChange={this.pricePointDropdownChanged}
          />
          <div className={"payment-column"}>
            <input key={"utf8"} name={"utf8"} type={"hidden"} value={"✓"} />
            <input
              key={"authenticity_token"}
              name={"authenticity_token"}
              type={"hidden"}
              value={this.props.csrfToken}
            />
            <input
              key={"registration[camper_id]"}
              name={"registration[camper_id]"}
              type={"hidden"}
              value={this.state.current_camper}
            />
            <input
              key={"registration[price_point_id]"}
              name={"registration[price_point_id]"}
              type={"hidden"}
              value={this.state.price_point_id}
            />
            <input
              key={"registration[liability_waiver]"}
              name={"registration[liability_waiver]"}
              type={"hidden"}
              value={this.state.liability_waiver}
            />
            <input
              key={"registration[shirt_size]"}
              name={"registration[shirt_size]"}
              type={"hidden"}
              value={this.state.shirt_size}
            />
            <input
              key={"registration[graduation_year]"}
              name={"registration[graduation_year]"}
              type={"hidden"}
              value={this.state.graduation_year}
            />
            <input
              key={"registration[notes]"}
              name={"registration[notes]"}
              type={"hidden"}
              value={this.state.notes}
            />
            <input
              key={"registration[liability_waiver_signature]"}
              name={"registration[liability_waiver_signature]"}
              type={"hidden"}
              value={this.state.liability_waiver_signature}
            />
            <input
              key={"registration[custom_data]"}
              name={"registration[custom_data]"}
              type={"hidden"}
              value={JSON.stringify(this.state.custom_data)}
            />
            <LoadingPanel loading_payment={this.state.loading_payment} />
          </div>
          <div className={"camper-info-row"}>
            <div id={"card-element"} className={"full-field"}></div>
            <div id={"card-errors"} role={"alert"}></div>
          </div>
          <div className={"camper-info-row"}>
            <img
              className={"visa-cc"}
              src={
                "https://res.cloudinary.com/buck/image/upload/v1454771871/visa.png"
              }
              alt={"we take visa"}
            />
            <img
              className={"master-cc"}
              src={
                "https://res.cloudinary.com/buck/image/upload/v1454771871/mastercard.png"
              }
              alt={"we take mastercard"}
            />
            <img
              className={"discover-cc"}
              src={
                "https://res.cloudinary.com/buck/image/upload/v1454771870/discover.png"
              }
              alt={"we take discover"}
            />
            <img
              className={"amex-cc"}
              src={
                "https://res.cloudinary.com/buck/image/upload/v1454771871/amex.png"
              }
              alt={"we take amex"}
            />
          </div>
          <div className={"discount-row"}>
            <button
              type={"button"}
              id={"remove-discount-code"}
              className={"remove-discount-code hidden"}
              onClick={this.resetDiscountCode}
            >
              {"Remove"}
            </button>
            <input
              className={"discount-box half-field"}
              type={"text"}
              name={"payment[discount_code]"}
              size={30}
              maxLength={30}
              value={this.state.discount_code}
              placeholder={"Enter Discount Code..."}
              ref={"discount_code"}
              data-name={"discount_code"}
              onChange={this.handleChange}
            />
            <button
              type={"button"}
              className={"verify-discount-code"}
              style={this.state.alt_button_styles}
              disabled={this.state.discounted}
              onClick={this.handleDiscountCode}
            >
              {"Apply"}
            </button>
          </div>
          <div className={"camper-info-row"}>
            <button
              type={"submit"}
              className={"button button-primary create payment-button"}
              style={this.props.buttonStyles}
              disabled={this.state.disabled}
              onClick={this.handleSubmit}
            >
              {"Register"}
            </button>
          </div>
        </div>
      </form>
    );
  }
}
