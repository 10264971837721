import * as React from 'react';
import * as ReactDom from 'react-dom';

class CartRegistration extends React.Component{
  constructor(props) {
    console.log(props)
    super(props);
  }
  formatCurrency(number){
    return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
  render() {
    return (<div className="cart-registration">
              <p>{this.props.first_name}</p>
              <p>{this.props.last_name}</p>
              <p>{this.props.price_point_data.title}</p>
              <p>{this.props.price_point_data.price}</p>
              <p>COMPLETE</p>
            </div>);
  };
};
