import * as React from "react";
import * as ReactDom from "react-dom";
import Cleave from "cleave.js/dist/cleave-react";

export default class WeightField extends React.Component {
  static displayName = "WeightField";

  constructor(props) {
    super(props);
    this.state = {
      weight: ""
    };
  }

  handleChange = event => {
    const { name } = event.target;
    return this.setState(
      {
        [name]: event.target.value
      },
      function() {
        return this.props.onChange(this.state.weight);
      }
    );
  };

  render() {
    return (
      <div className="weight-select-container">
        <span className="error-span"></span>
        <label className="weight">Weight</label>
        <Cleave
          id="new-weight-cleave"
          className={"field"}
          type={"text"}
          maxLength="3"
          placeholder={"Weight (lbs)"}
          value={this.state.weight}
          name={"weight"}
          ref={"weight"}
          options={{ numericOnly: true }}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
