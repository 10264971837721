// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import * as React from "react";
import * as ReactDom from "react-dom";

import Cleave from "cleave.js/dist/cleave-react";

export default class MedicalForm extends React.Component {
  static displayName = "MedicalForm";

  state = {
    physician: "",
    physician_contact_number: "",
    insurer: "",
    group_number: "",
    medications: "",
    conditions: "",
    allergies: "",
    diabetes: false,
    asthma: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  handlePhoneChange = e => {
    const { name } = e.target;
    if (name == "guardian_contact_number") {
      return this.setState({
        [name]: this.state.guardian_contact_country_code + " " + e.target.value
      });
    } else if (name == "emergency_contact_number") {
      return this.setState({
        [name]: this.state.emergency_contact_country_code + " " + e.target.value
      });
    } else {
      return this.setState({ [name]: e.target.value });
    }
  };

  valid = () => {
    return (
      this.validateLength(this.state.physician, 5) &&
      this.validateLength(this.state.physician_contact_number, 9) &&
      this.validateLength(this.state.insurer, 4) &&
      this.validateLength(this.state.group_number, 2)
    );
  };

  validateLength = (field, requiredLength) => {
    if (field.length > requiredLength) {
      return true;
    }
  };

  handleChange = e => {
    const { name } = e.target;
    return this.setState({ [name]: e.target.value });
  };

  handleChecked = e => {
    const { name } = e.target;
    console.log(name);
    return this.setState({ [name]: e.target.checked });
  };

  checkInput = e => {
    const itemClass = e.target.className;
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    return this.showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      const errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      return $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      return $(errorSpan).html("");
    }
  };

  handleCamperSubmit = e => {
    e.preventDefault();
    let newData = {
      physician: this.state.physician,
      physician_contact_number: this.state.physician_contact_number,
      insurer: this.state.insurer,
      group_number: this.state.group_number,
      allergies: this.state.allergies,
      medications: this.state.medications,
      conditions: this.state.conditions,
      diabetes: this.state.diabetes,
      asthma: this.state.asthma
    };
    this.props.saveValues(newData);
    return this.props.nextStep();
  };

  render() {
    return (
      <form className={"new-step-form"}>
        <div className={"place"}>
          <h1 className={"form-heading"}>{"Step 2. Medical Information"}</h1>
          <div className={"camper-info-form"}>
            <label className={"up-size big-span"}>
              {"Physician Information: *"}
            </label>
            <span className={"error-span"}></span>
            <input
              type={"text"}
              className={"phys full-field"}
              placeholder={"Physician Name *"}
              data-error-message={
                "Physician Name must be more than the specified number of characters."
              }
              data-min-length={"4"}
              name={"physician"}
              ref={"physician"}
              value={this.state.physician}
              onChange={this.handleChange}
              onBlur={this.checkInput}
            />
            <span className={"error-span"}></span>
            <Cleave
              id="phone-mask"
              className={"ecn phone-us field two-thirds-field"}
              type={"tel"}
              size={10}
              pattern={"[0-9]{10}"}
              name={"physician_contact_number"}
              ref={"physician_contact_number"}
              placeholder={"Physician Contact #"}
              data-error-message={
                "Physician Phone must be 10 characters. If you are still having issues, clear the field and try again."
              }
              data-min-length={"10"}
              options={{ numericOnly: true, blocks: [3, 3, 4], delimiter: "-" }}
              onChange={this.handlePhoneChange}
              onBlur={this.checkInput}
            />
          </div>
          <div className={"camper-info-row"}>
            <label className={"up-size big-span"}>
              {"Insurance Information: *"}
            </label>
            <span className={"error-span"}></span>
            <input
              type={"text"}
              className={"insurance` full-field"}
              placeholder={"Insurance Provider *"}
              required={true}
              data-error-message={
                "Insurance Provider must be filled out. No abbreviations please!"
              }
              data-min-length={"4"}
              name={"insurer"}
              ref={"insurer"}
              value={this.state.insurer}
              onChange={this.handleChange}
              onBlur={this.checkInput}
            />
            <input
              type={"text"}
              className={"group-number full-field"}
              placeholder={"Insurance Group / Policy Number *"}
              required={true}
              data-error-message={
                "Insurance Group / Policy Number must be more than the specified number of characters"
              }
              data-min-length={"3"}
              name={"group_number"}
              ref={"group_number"}
              value={this.state.group_number}
              onChange={this.handleChange}
              onBlur={this.checkInput}
            />
          </div>
          <div className={"health-container camper-info-row"}>
            <span className={"up-size"}>{"Health Overview:"}</span>
            <span className={"error-span"}></span>
            <div className={"camper-info-row"}>
              <textarea
                rows={{ 5: 5 }}
                className={"meds full-field"}
                placeholder={"Please list any Medications..."}
                name={"medications"}
                ref={"medications"}
                onChange={this.handleChange}
                onBlur={this.checkInput}
                value={this.state.medications}
              ></textarea>
              <textarea
                rows={{ 5: 5 }}
                className={"conds full-field"}
                placeholder={"Please list any Medical Conditions - etc...."}
                name={"conditions"}
                ref={"conditions"}
                onChange={this.handleChange}
                onBlur={this.checkInput}
                value={this.state.conditions}
              ></textarea>
            </div>
            <div className={"camper-info-row"}>
              <textarea
                rows={{ 5: 5 }}
                className={"allergiess full-field"}
                placeholder={"Please list any allergies...."}
                name={"allergies"}
                ref={"allergies"}
                onChange={this.handleChange}
                onBlur={this.checkInput}
                value={this.state.allergies}
              ></textarea>
            </div>
            <div className={"camper-info-row"}>
              <div className={"check-split third-field"}>
                <span htmlFor={"diabetes"} className={"check-span up-size"}>
                  {"Diabetes"}
                </span>
                <input
                  type={"checkbox"}
                  className={"diabetes"}
                  name={"diabetes"}
                  ref={"diabetes"}
                  value={this.state.diabetes}
                  onChange={this.handleChecked}
                />
              </div>
              <div className={"check-split third-field"}>
                <span htmlFor={"asthma"} className={"check-span up-size"}>
                  {"Asthma"}
                </span>
                <input
                  type={"checkbox"}
                  className={"asthma"}
                  name={"asthma"}
                  ref={"asthma"}
                  value={this.state.asthma}
                  onChange={this.handleChecked}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={"camper-info-row"}>
          <button
            type={"submit"}
            className={"button button-primary create"}
            disabled={!this.valid()}
            style={this.props.buttonStyles}
            onClick={this.handleCamperSubmit}
          >
            {"Next Step"}
          </button>
        </div>
      </form>
    );
  }
}
