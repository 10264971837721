import * as React from "react";
import * as ReactDom from "react-dom";
import Cleave from "cleave.js/dist/cleave-react";

export default class TextField extends React.Component {
  static displayName = "TextField";

  constructor(props) {
    super(props);
    console.log(this.props)
    // field classes?
    // field name
    this.state = {
      field_name: '',
      field_required: false,
      field_value: ''
    };
  }

  handleChange = event => {
    const { name } = event.target;
    return this.setState(
      {
        [name]: event.target.value
      },
      function() {
        return this.props.onChange(this.state.field_value);
      }
    );
  };

  render() {
    return (
      <div className="text-field-container">
        <span className="error-span"></span>
        <label className="text-field full-field"></label>
        <input />
      </div>
    );
  }
}
