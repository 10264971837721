import React from "react";
import ReactDOM from "react-dom";
import SignatureCanvas from "react-signature-canvas";

export default class SignatureField extends React.Component {
  static displayName = "SignatureField";

  state = {
    trimmedDataUrl: null
  };

  sigPad = {}

  clear(){ 
    this.sigPad.clear();
  }

  save(){
    console.log(this.sigPad.getTrimmedCanvas())
    this.setState({ trimmedDataUrl: this.sigPad.getTrimmedCanvas() })

  }

  componentDidUpdate() {
    console.log(this.sigPad.getTrimmedCanvas())
  }

  render() {
    let { trimmedDataUrl } = this.state
    <SignatureCanvas
      penColor="green"
    >
    <div>
        <button onClick={this.clear}>
          Clear
        </button>
        <button onClick={this.trim}>
          Trim
        </button>
      </div>
  }
}
