// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import * as React from "react";
import * as ReactDom from "react-dom";

import BirthDateDropdown from "./birth_date_dropdown.js";
import StateDropdown from "./state_dropdown.js";
import CountryCodeDropdown from "./country_code_dropdown.js";
import CampWaitlistModal from "./camp_waitlist_modal.js";
import Cleave from "cleave.js/dist/cleave-react";
import GenderField from "./gender_field.js";

export default class CamperInfoForm extends React.Component {
  static displayName = "CamperInfoForm";

  constructor(props) {
    super(props);
    this.state = {
      showModal: null,
      first_name: "",
      last_name: "",
      birth_date: new Date("2004", "01", "01"),
      player_phone_number: "",
      emergency_contact: "",
      emergency_contact_country_code: "+1",
      emergency_contact_number: "",
      gender: "Male",
      grade: "",
      guardian_contact: "",
      guardian_contact_country_code: "+1",
      guardian_contact_number: "",
      guardian_contact_email: "",
      guardian_contact_relationship: "",
      email: "",
      address: "",
      city: "",
      state: "AL - Alabama",
      zip: "",
      price_points: JSON.parse(this.props.price_points),
    };
  }

  componentDidMount() {
    console.log("alright alright alright.");
  }

  campFull = () => {
    return (
      this.state.price_points.length == this.createFullPricePointArray().length
    );
  };

  genderFieldChanged = (selectedGender) => {
    this.state.gender = selectedGender;
    return this.forceUpdate();
  };

  setGuardianFields = () => {
    if (this.props.collect_guardian_info) {
      return (
        <div className={"camper-info-row"}>
          <span className={"error-span"}></span>
          <label className={"big-span"}>
            {"Guardian Contact Information: *"}
          </label>
          <input
            type={"text"}
            className={"full-field"}
            placeholder={"Guardian Name"}
            data-error-message={
              "Guardian Contact Name must be longer than the specified number of characters."
            }
            data-min-length={"5"}
            name={"guardian_contact"}
            ref={"guardian_contact"}
            minLength={5}
            defaultValue={this.props.emergency_contact}
            onChange={this.handleChange}
            onBlur={this.checkInput}
          />
          <input
            type={"text"}
            className={"full-field"}
            placeholder={"Guardian Relationship - Mother, Father, etc"}
            data-error-message={
              "Guardian Relationship must be longer than the specified number of characters."
            }
            data-min-length={"5"}
            name={"guardian_contact_relationship"}
            ref={"guardian_contact_relationship"}
            minLength={2}
            defaultValue={this.props.emergency_contact}
            onChange={this.handleChange}
            onBlur={this.checkInput}
          />
          <div className={"half"}>
            <CountryCodeDropdown
              value={this.state.guardian_contact_country_code}
              onChange={this.guardianCountryCodeDropdownChanged}
            />
            <Cleave
              type={"tel"}
              id={"phone-mask"}
              className={"ecn phone-us field two-thirds-field"}
              size={10}
              pattern={"[0-9]{10}"}
              id={"phone-mask"}
              name={"guardian_contact_number"}
              ref={"guardian_contact_number"}
              placeholder={"Guardian Contact #"}
              data-error-message={
                "Guardian Phone must be 10 characters. If you are still having issues, clear the field and try again."
              }
              data-min-length={"10"}
              options={{ blocks: [3, 3, 4], delimiter: "-", numericOnly: true }}
              onChange={this.handlePhoneChange}
              onBlur={this.checkInput}
            />
          </div>
          <input
            type={"email"}
            className={"half full-field"}
            placeholder={"Guardian Email"}
            data-error-message={
              "Guardian Relationship must be longer than the specified number of characters."
            }
            data-min-length={"5"}
            name={"guardian_contact_email"}
            ref={"guardian_contact_email"}
            minLength={5}
            required={true}
            defaultValue={this.props.emergency_contact}
            onChange={this.handleChange}
            onBlur={this.checkInput}
          />
        </div>
      );
    }
  };

  setFullPricePointFlag = () => {
    const pricePoints = this.state.price_points;
    for (let i = 0; i < pricePoints.length; i++) {
      if (pricePoints[i].registration_count >= pricePoints[i].capacity) {
        return true;
      }
    }
    return false;
  };

  createOpenPricePointArray = () => {
    const pricePoints = this.state.price_points;
    let openPricePoints = [];
    for (let i = 0; i < pricePoints.length; i++) {
      if (pricePoints[i].registration_count <= pricePoints[i].capacity) {
        openPricePoints.push(pricePoints[i]);
      }
    }
    return openPricePoints;
  };

  createFullPricePointArray = () => {
    const pricePoints = this.state.price_points;
    let fullPricePoints = [];
    for (let i = 0; i < pricePoints.length; i++) {
      if (pricePoints[i].registration_count >= pricePoints[i].capacity) {
        fullPricePoints.push(pricePoints[i]);
      }
    }
    return fullPricePoints;
  };

  checkInput = (e) => {
    let itemClass = e.target.className;
    let item = e.target;
    // console.log(e.target.type)
    let val = e.target.value;
    let errorSpan = item.parentElement.querySelector(".error-span");
    return this.showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      var errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      return $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      return $(errorSpan).html("");
    }
  };

  validateEmail = (email) => {
    var emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  };

  validateLength = (field, requiredLength) => {
    if (field.length > requiredLength) {
      return true;
    }
  };

  valid = () => {
    if (
      this.validateLength(this.state.first_name, 1) &&
      this.validateLength(this.state.last_name, 1) &&
      this.validateEmail(this.state.email) &&
      this.validateLength(this.state.emergency_contact, 4) &&
      this.validateLength(this.state.address, 4) &&
      this.state.city &&
      this.validateLength(this.state.zip, 2)
    ) {
      return true;
    } else {
      return false;
    }
  };

  stateDropdownChanged = (newState) => {
    this.state.state = newState;
    return this.forceUpdate();
  };

  countryCodeDropdownChanged = (newCode) => {
    this.state.emergency_contact_country_code = newCode;
    return this.forceUpdate();
  };

  guardianCountryCodeDropdownChanged = (newCode) => {
    this.state.guardian_contact_country_code = newCode;
    return this.forceUpdate();
  };

  dateChanged = (newDate) => {
    this.state.birth_date = newDate;
    return this.forceUpdate();
  };

  validateStreetAddress = (address) => {};

  handleChange = (e) => {
    const { name } = e.target;
    return this.setState({ [name]: e.target.value });
  };

  handlePhoneChange = (e) => {
    const { name } = e.target;
    if (name == "guardian_contact_number") {
      return this.setState({
        [name]: this.state.guardian_contact_country_code + " " + e.target.value,
      });
    } else if (name == "emergency_contact_number") {
      return this.setState({
        [name]:
          this.state.emergency_contact_country_code + " " + e.target.value,
      });
    } else {
      return this.setState({ [name]: e.target.value });
    }
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    const data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      birth_date: this.state.birth_date,
      emergency_contact: this.state.emergency_contact,
      emergency_contact_number: this.state.emergency_contact_number,
      gender: this.state.gender,
      guardian_contact: this.state.guardian_contact,
      guardian_contact_number: this.state.guardian_contact_number,
      guardian_contact_email: this.state.guardian_contact_email,
      guardian_contact_relationship: this.state.guardian_contact_relationship,
      email: this.state.email,
      player_phone_number: this.state.player_phone_number,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
    };
    this.props.saveValues(data);
    return this.props.nextStep();
  };

  render() {
    return (
      <form className={"new-step-form"} autoComplete={"off"}>
        <CampWaitlistModal
          waitlist_price_point_ids={this.props.waitlist_price_point_ids}
          button_style={this.props.buttonStyles}
          camp_full={this.campFull()}
          show_modal={this.setFullPricePointFlag()}
          full_price_points={this.createFullPricePointArray()}
          open_price_points={this.createOpenPricePointArray()}
        />
        <div className={"buck-test"}>
          <h1 className={"form-heading"}>{"Step 1. Attendee Information"}</h1>
        </div>
        <div className={"camper-info-row"}>
          <label className={"big-span"}></label>
          <span className={"error-span"}></span>
          <input
            type={"text"}
            className={"full-field"}
            placeholder={"First Name *"}
            data-error-message={"First Name must be at least two characters"}
            data-min-length={"2"}
            name={"first_name"}
            ref={"first_name"}
            minLength={2}
            required={true}
            onChange={this.handleChange}
            onBlur={this.checkInput}
          />
          <input
            type={"text"}
            className={"full-field"}
            placeholder={"Last Name *"}
            data-error-message={"Last Name must be at least two characters"}
            data-min-length={"2"}
            name={"last_name"}
            ref={"last_name"}
            minLength={2}
            required={true}
            onChange={this.handleChange}
            onBlur={this.checkInput}
          />
        </div>
        <div className={"camper-info-row"}>
          <label htmlFor={"email"}>{"Player Email: *"}</label>
          <span className={"error-span"}></span>
          <input
            type={"email"}
            className={"camper-email full-field"}
            placeholder={"Player Email *"}
            data-error-message={
              "Email must be valid and longer than the specified number of characters"
            }
            data-min-length={"5"}
            name={"email"}
            ref={"email"}
            minLength={5}
            required={true}
            onChange={this.handleChange}
            onBlur={this.checkInput}
          />
          <div className={"half"}>
            <span className={"error-span"}></span>
            <input
              type={"text"}
              className={"grade full-field"}
              placeholder={"Current Grade or Year"}
              data-error-message={"The Grade field needs be filled out"}
              data-min-length={"1"}
              name={"grade"}
              ref={"grade"}
              minLength={1}
              required={true}
              onChange={this.handleChange}
              onBlur={this.checkInput}
            />
          </div>
          <div className={"half"}>
            <BirthDateDropdown
              onChange={this.dateChanged}
              birth_date={this.state.birth_date}
            />
          </div>
          {this.props.category == "coed" && (
            <div className={"camper-info-row"}>
              <GenderField
                onChange={this.genderFieldChanged}
                gender={this.state.gender}
              />
            </div>
          )}
        </div>
        <div className={"camper-info-row"}>
          <label className={"big-span"}>
            {"Emergency Contact Information: *"}
          </label>
          <span className={"error-span"}></span>
          <input
            type={"text"}
            className={"full-field"}
            placeholder={"Emergency Contact Full Name"}
            data-error-message={
              "Emergency Contact Name and Number must be longer than the specified number of characters."
            }
            data-min-length={"4"}
            name={"emergency_contact"}
            ref={"emergency_contact"}
            minLength={4}
            required={true}
            onChange={this.handleChange}
            onBlur={this.checkInput}
          />
        </div>
        <div className="half">
          <CountryCodeDropdown
            value={this.state.emergency_contact_country_code}
            onChange={this.countryCodeDropdownChanged}
          />
          <Cleave
            id="phone-mask"
            className={"ecn phone-us field two-thirds-field"}
            type={"tel"}
            size={10}
            pattern={"[0-9]{10}"}
            name={"emergency_contact_number"}
            ref={"emergency_contact_number"}
            placeholder={"Emergency Contact #"}
            data-error-message={
              "EC Phone must be 10 characters. If you are still having issues, clear the field and try again."
            }
            data-min-length={"10"}
            options={{ blocks: [3, 3, 4], delimiter: "-", numericOnly: true }}
            onChange={this.handlePhoneChange}
            onBlur={this.checkInput}
          />
        </div>
        <div className={"camper-info-row"}>{this.setGuardianFields()}</div>
        <div className={"camper-info-row address"}>
          <label>{"Primary Address: *"}</label>
          <span className={"error-span"}></span>
          <input
            type={"text"}
            className={"address full-field"}
            placeholder={"Street Address"}
            data-error-message={"Please enter a valid address"}
            data-min-length={"4"}
            name={"address"}
            ref={"address"}
            minLength={4}
            onChange={this.handleChange}
            onBlur={this.checkInput}
          />
        </div>
        <div className={"camper-info-row address"}>
          <span className={"error-span"}></span>
          <input
            type={"text"}
            className={"city full-field"}
            placeholder={"City"}
            data-error-message={
              "City must be longer than the specified number of characters."
            }
            data-min-length={"4"}
            name={"city"}
            ref={"city"}
            minLength={4}
            onChange={this.handleChange}
            onBlur={this.checkInput}
          />
        </div>
        <div className="camper-info-row">
          <div className="error-span"></div>
          <StateDropdown
            value={this.state.state}
            onChange={this.stateDropdownChanged}
          />
          <input
            type={"text"}
            className={"zip field third-field"}
            placeholder={"Zip Code (i.e. 20008)"}
            data-error-message={"Please enter a valid zip code."}
            data-min-length={"2"}
            name={"zip"}
            ref={"zip"}
            minLength={2}
            maxLength={10}
            onChange={this.handleChange}
            onBlur={this.checkInput}
          />
        </div>
        <button
          type={"submit"}
          className={"button button-primary create"}
          style={this.props.buttonStyles}
          disabled={!this.valid()}
          onClick={this.saveAndContinue}
        >
          {"Next Step"}
        </button>
      </form>
    );
  }
}
