// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const monthName = monthNumberStartingFromZero => ["January", "February", "March", "April", "May", "June", "July","August", "September", "October", "November", "December"][monthNumberStartingFromZero];
const dayName = function(date) {
  const dayNameStartingWithSundayZero = date.getDay();
  return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][dayNameStartingWithSundayZero];
};

import React from 'react';
import ReactDOM from 'react-dom';

export default class BirthDateDropdown extends React.Component {
  static displayName = 'BirthDateDropdown';
  static defaultProps = {birth_date: new Date()};

  setUpDays = () =>{
    let options = [];
    for (let day = 1; day <= 31; day++) {
      options.push(
        <option value={day} key={day}>
          {day}
        </option>);

    }
    return options;
  }

  setUpMonths = () => {
    const options = [];
    for (let month of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]){
      options.push(
        <option value={month} key={month}>
          {monthName(month)}
        </option>);
    }
     return options;
  };

  setUpYears = () => {
    const options = [];
    for (let year of Array.from(__range__(1940, 2021, true))){
      options.push(
        <option value={year} key={year}>
          {year}
        </option>);
    }
     return options;
  };


  onYearChange = (event) => {
    const newDate = new Date(event.target.value, this.props.birth_date.getMonth(), this.props.birth_date.getDate());
    return this.props.onChange(newDate);
  };

  onMonthChange = (event) => {
    const newDate = new Date(this.props.birth_date.getFullYear(), event.target.value, this.props.birth_date.getDate());
    return this.props.onChange(newDate);
  };

  onDateChange = (event) => {
    const newDate = new Date(this.props.birth_date.getFullYear(), this.props.birth_date.getMonth(), event.target.value);
    return this.props.onChange(newDate);
  };

  render() {
    return (
      <div className="birth-date-container">
        <div className="birth-date">
          <span className="bd-text">
            Birthdate
          </span>
        </div>
        <div className="birth-date-half">
          <select className="month-select third-field" value={this.props.birth_date.getMonth()} onChange={this.onMonthChange}>
            { this.setUpMonths() }
          </select>
        </div>
        <div className="birth-date-quarter">
          <select className="third-field" value={this.props.birth_date.getDate()} onChange={this.onDateChange}>
            { this.setUpDays() }
          </select>
        </div>
        <div className="birth-date-quarter">
           <select className="year-select third-field" value={this.props.birth_date.getFullYear()} onChange={this.onYearChange}>
            { this.setUpYears() }
          </select>
        </div>
      </div>)
  }
}

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}