// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import * as React from 'react';
import * as ReactDom from 'react-dom';

export default class CountryCodeDropdown extends React.Component {
  static displayName = 'CountryCodeDropdown';
  state = { country_code: "+1" };

  onCodeChange = (event) => {
    return this.props.onChange(event.target.value);
  };

  countryCodeArray = () => {
    return [  
              {
                name: "United States",
                dial_code: "+1",
                code: "US"
              },
              {
                name: "Brazil",
                dial_code: "+55",
                code: "BR"
              },
              {
                name: "Canada",
                dial_code: "+1",
                code: "CA"
              },
              {
                name: "Costa Rica",
                dial_code: "+506",
                code: "CR"
              },
              {
                name: "United Kingdom",
                dial_code: "+44",
                code: "GB"
              },
              {
                name: "Argentina",
                dial_code: "+54",
                code: "AR"
              },
              {
                name: "Australia",
                dial_code: "+61",
                code: "AU"
              },
              {
                name: "Peru",
                dial_code: "+51",
                code: "PE"
              },
              {
                name: "Austria",
                dial_code: "+43",
                code: "AT"
              },
              {
                name: "Bahamas",
                dial_code: "+1242",
                code: "BS"
              },
              {
                name: "Belgium",
                dial_code: "+32",
                code: "BE"
              },
              {
                name: "Belize",
                dial_code: "+501",
                code: "BZ"
              },
              {
                name: "Chile",
                dial_code: "+56",
                code: "CL"
              },
              {
                name: "China",
                dial_code: "+86",
                code: "CN"
              },
              {
                name: "Colombia",
                dial_code: "+57",
                code: "CO"
              },
              {
                name: "Dominican Republic",
                dial_code: "+1849",
                code: "DO"
              },
              {
                name: "Ecuador",
                dial_code: "+593",
                code: "EC"
              },
              {
                name: "Germany",
                dial_code: "+49",
                code: "DE"
              },
              {
                name: "Finland",
                dial_code: "+358",
                code: "FI"
              },
              {
                name: "France",
                dial_code: "+33",
                code: "FR"
              },
              {
                name: "Israel",
                dial_code: "+972",
                code: "IL"
              },
              {
                name: "Italy",
                dial_code: "+39",
                code: "IT"
              },
              {
                name: "Jamaica",
                dial_code: "+1876",
                code: "JM"
              },
              {
                name: "Japan",
                dial_code: "+81",
                code: "JP"
              },
              {
                name: "Norway",
                dial_code: "+47",
                code: "NO"
              },
              {
                name: "Paraguay",
                dial_code: "+595",
                code: "PY"
              },
              {
                name: "Poland",
                dial_code: "+48",
                code: "PL"
              },
              {
                name: "Portugal",
                dial_code: "+351",
                code: "PT"
              },
              {
                name: "Spain",
                dial_code: "+34",
                code: "ES"
              },
              {
                name: "Sweden",
                dial_code: "+46",
                code: "SE"
              },
              {
                name: "Switzerland",
                dial_code: "+41",
                code: "CH"
              }
            ]
  };

  setUpCountryCodeOptions(){
    const options = [];
    for (let country of this.countryCodeArray()){
      options.push(
        <option value={country.dial_code} key={`${country.code} ${country.dial_code}`}>
          {`${country.code} ${country.dial_code}`}
        </option>);
    }
     return options;
  }

  render() {
    return (
      <div className="country-code-container quarter">
        <select className="country-code-select field"  value={this.props.country_code} onChange={this.onCodeChange}>
          { this.setUpCountryCodeOptions() }
        </select>
      </div>
    )
  }
}

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}