// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as React from "react";
import * as ReactDom from "react-dom";

export default class PositionDropdown extends React.Component {
  static displayName = "PositionDropdown";

  state = {
    position: this.props.position,
    full_price_points: this.props.price_points,
  };

  onPositionChange = (event) => {
    return this.props.onChange(event.target.value);
  };

  sportSpecificPositionSelect = (sport) => {
    if (sport === "soccer") {
      return ["Forward", "Midfielder", "Defender", "Goalkeeper"];
    } else if (sport === "basketball") {
      return ["PG", "SG", "SF", "PF", "C"];
    } else if (sport === "lacrosse") {
      return ["Attacker", "Midfielder", "Defender", "Goalkeeper"];
    } else if (sport === "hockey") {
      return ["Defenseman"];
    } else if (sport === "volleyball") {
      return [
        "Outside Hitter",
        "Right Side Hitter",
        "Opposite Hitter",
        "Setter",
        "Middle Blocker",
        "Libero",
      ];
    } else if (sport === "baseball") {
      return ["C", "P", "1B", "2B", "3B", "SS", "OF", "IF"];
    } else if (sport === "softball") {
      return ["C", "P", "1B", "2B", "3B", "SS", "OF", "IF"];
    } else if (sport === "wrestling") {
      return ["Wrestler"];
    } else if (sport === "men soccer") {
      return ["RB", "CB", "LB", "DCM", "CM", "ACM", "RW", "LW", "F", "GK"];
    }
    if (sport === "sports-performance") {
      return [
        "soccer",
        "football",
        "basketball",
        "baseball",
        "lacrosse",
        "field hockey",
        "golf",
        "tennis",
        "other",
      ];
    } else {
      return ["All / Any"];
    }
  };

  setUpAvailablePositions = () => {
    let positionArray = this.sportSpecificPositionSelect(this.props.sport);
    let fullPricePoints = this.props.full_price_points;
    const positionsToRemove = [
      ...new Set(fullPricePoints?.map((pp) => pp.position)),
    ];
    let availablePositions = positionArray?.filter(
      (position) => !positionsToRemove.includes(position)
    );
    return availablePositions;
  };

  setUpOptions = () => {
    let results = [];

    for (let position of Array.from(this.setUpAvailablePositions())) {
      results.push(
        <option value={position} key={position}>
          {position}
        </option>
      );
    }
    return results;
  };
  render() {
    let position;
    return (
      <select
        className={"position field"}
        name={"position"}
        ref={"position"}
        value={this.props.position}
        onChange={this.onPositionChange}
      >
        {this.setUpOptions()}
      </select>
    );
  }
}
