// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as React from "react";
import * as ReactDom from "react-dom";

import { WaitlistButton } from "./waitlist_button.js";

export default class CampWaitlistModal extends React.Component {
  static displayName = "CampWaitlistModal";

  constructor(props) {
    super(props);

    this.state = {
      cssStatusVariable: "none",
      displayModal: props.show_modal,
      message: ""
    };
  }

  componentDidMount() {
    this.setStatusVariable();
  }

  setStatusVariable = () => {
    if (this.props.show_modal == true) {
      this.setState({ cssStatusVariable: "block" });
    }
  };

  hideModal = () => {
    this.setState({ displayModal: false, cssStatusVariable: "none" });
  };

  renderCampMessage = () => {
    return (
      <div className={"list-container"}>
        <ul className={"full-pp-list"}>{this.buildFullPricePointList()}</ul>
      </div>
    );
  };

  offSeasonMessage = () => {
    return "We are currently out of camp season. Please check back soon for updates.";
  };

  campFullMessage = () => {
    return "All of this camp's spaces are full. Please contact support@firewoodcamps.com for additional details.";
  };

  updateWaitlist = () => {};

  setbottomButton = () => {
    if (this.props.camp_full) {
      return (
        <button className={"continue-btn"} href={"/"}>
          {"Back to Homepage"}
        </button>
      );
    } else {
      return (
        <button
          style={this.props.button_style}
          className={"continue-btn"}
          onClick={this.hideModal}
        >
          {"Continue to Registration"}
        </button>
      );
    }
  };

  openTypesText = () => {
    if (this.props.camp_full) {
      return "Event is full. Click below to add yourself to the waitlist.";
    } else {
      return "Space is still available, just not longer in the aforementioned capacity.";
    }
  };

  setWaitlistText = () => {
    if (this.props.camp_full) {
      return "Camp is completely full. Click below to add yourself to the waitlist.";
    } else {
      return "Camp is still open for registration but is full for the following price points:";
    }
  };

  buildFullPricePointList = () => {
    let results = [];
    results.push(
      <li className={"row"} key={0}>
        {this.setWaitlistText()}
      </li>
    );
    for (let pp of Array.from(this.props.full_price_points)) {
      results.push(
        <li className={"waitlist-item row"} key={pp.id}>
          <span className={"waitlist-title"}>{pp.title}</span>
          <WaitlistButton
            waitlist_price_point_ids={this.props.waitlist_price_point_ids}
            price_point_id={pp.id}
            primary_color={this.props.button_style.color}
            secondary_color={this.props.button_style.backgroundColor}
          />
        </li>
      );
    }
    return results;
  };

  render() {
    return (
      <div
        className={
          "camp-status-modal-container " + `${this.state.cssStatusVariable}`
        }
        style={this.props.button_style}
      >
        <div className={"heading"}>
          <h1 className={"camp-status-modal-heading"}>
            {"Important Camp Information:"}
          </h1>
        </div>
        <div className={"modal-text"}>
          <span className={"camp-msg"}>{this.renderCampMessage()}</span>
        </div>
        <div className={"modal-footer"}>{this.setbottomButton()}</div>
      </div>
    );
  }
}
