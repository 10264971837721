// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as React from 'react';
import * as ReactDom from 'react-dom';

export class PricePointSelect extends React.Component {
  static displayName = 'PricePointSelect';

  constructor(props) {
    super(props);
    this.state = {
                    price_point: props.primary_price_point,
                    price_point_id: props.primary_price_point.id,
                    price_points: props.price_points
                  };
  }

  onComponentDidMount = () => {
    // this.setPricePointIfNeeded()
  };

  onPricePointChange = (event) => {
    this.setState({price_point_id: event.target.value});
    return this.props.onChange(event.target.value);
  };

  setAvailablePricePoints = (pricePointArray) => {
    const pricePoints = this.state.price_points;
    let openPricePoints = [];
    for (let i = 0; i < pricePoints.length; i++) {
      if (pricePoints[i].registration_count < pricePoints[i].capacity){
        openPricePoints.push(pricePoints[i]);
      }
    }
    return openPricePoints;
  };

  setUpOptions = () => {
    let options = []
    for (let price_point of Array.from(this.setAvailablePricePoints(this.props.price_points))){
      options.push(
        <option value={price_point.id} key={price_point.id}>
          { price_point.title + " - $" + (price_point.price_cents/100).toString() }
        </option>)
    }
    return options;
  }

  render() {
    return(
      <select className='price_point full-field' 
              id='price_point_select'
              name='price_point_id'
              ref='price_point_id'
              value={this.state.price_point_id}
              onChange={this.onPricePointChange}>
        { this.setUpOptions() }
      </select>
    )
  }
}