/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from "@rails/ujs";
import * as ActiveStorage from "activestorage";

import React from "react";
import ReactDom from "react-dom";
import ReactRailsUJS from "react_ujs";
import PropTypes from "prop-types";

import * as selectize from "selectize";
import * as DataTable from "datatables.net-dt";
import * as responsive from "datatables.net-responsive-dt";

const componentRequireContext = require.context("../components", true);

ReactRailsUJS.useContext(componentRequireContext);
window.componentRequireContext = componentRequireContext;

ReactRailsUJS.mountComponents();

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

window.Stimulus = Application.start();
const context = require.context("../controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

import "swiper/swiper-bundle.css";
import "datatables.net-dt/css/jquery.dataTables.css";
import "toastify-js/src/toastify.css";
import "@fortawesome/fontawesome-free/css/all";
import "normalize-css";

require.context("../images", true);

import "../stylesheets/application.scss";

import "trix";
import "@rails/actiontext";
import "chartkick";
import "chart.js";
import "youtube";

import("../src/tippy_popovers");
import("../src/landing_page_slider");
import("../src/datatiles");
import("../src/datatables");
import("../src/uppy");
import("../src/uppy_document");
import("../src/main_menu");
import("../src/dashboard_menu");
import("../src/user_menu");
import("../src/camps");
import("../src/password_meter");
import("../src/mailer_dashboard");
import("../src/sms_notifications");
import("../src/date_select_wrapper.jsx");

ActiveStorage.start();
Rails.start();
