// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as React from "react";
import * as ReactDom from "react-dom";
import PositionDropdown from "./position_dropdown.js";
import HeightField from "./height_field.js";
import WeightField from "./weight_field.js";

export default class TexasStateSportForm extends React.Component {
  static displayName = "TexasStateSportForm";
  constructor(props) {
    super(props);
    let defaultPosition = this.setDefaultPosition(this.props.sport);
    this.state = {
      height: "",
      weight: "Not Collected",
      position: defaultPosition,
      gpa: "",
      sat: "",
      act_score: "",
      club_team: "",
      notes: "",
      roommate_preference: "",
      peers_attending: "",
      graduation_year: "2022",
      shirt_size: "N/A",
      custom_data: {},
    };
    this.setDefaultPosition = this.setDefaultPosition.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  setDefaultPosition = (sport) => {
    if (sport === "soccer") {
      return "Forward";
    } else if (sport === "basketball") {
      return "PG";
    } else if (sport === "lacrosse") {
      return "Attack";
    } else if (sport === "hockey") {
      return "Forward";
    } else if (sport === "volleyball") {
      return "Outside Hitter";
    } else if (sport === "baseball") {
      return "C";
    } else if (sport === "softball") {
      return "C";
    } else if (sport === "wrestling") {
      return "Wrestler";
    } else if (sport === "men soccer") {
      return "CM";
    } else {
      return "All / Any";
    }
  };

  heightChanged = (newHeight) => {
    this.state.height = newHeight;
    return this.forceUpdate();
  };


  valid = () => {
    return this.state.height;
  };

  validateEmail = (email) => {
    const emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  };

  validateLength = (field, requiredLength) => {
    if (field.length > requiredLength) {
      return true;
    }
  };

  validateStreetAddress = (address) => {
    return console.log("validate");
  };

  capitalizeString = (string) => {
    const capped = string.charAt(0).toUpperCase() + string.slice(1);
    return capped;
  };

  addRoommatePreferenceField = () => {
    if (this.props.event_type == "traditional") {
      return (
        <div className="half">
          <label>Roommate Preferences:</label>
          <span className="error-span"></span>
          <textarea
            rows={{ 5: 5 }}
            name="roommate_preference"
            ref="roommate_preference"
            value={this.state.roommate_preference}
            onChange={this.handleChange}
            placeholder="Please list any roommate preferences..."
          ></textarea>
        </div>
      );
    }
  };

  addIdCampFields = () => {
    if (this.props.event_type != "day-camp") {
      return (
        <div className="camper-info-row">
          <div className="half">
            <label>GPA:</label>
            <span className="error-span"></span>
            <input
              type="text"
              className="gpa"
              placeholder="Enter GPA"
              name="gpa"
              ref="gpa"
              value={this.state.gpa}
              data-error-message={
                "GPA field must not be blank - N/A is a valid response."
              }
              data-min-length={"2"}
              onChange={this.handleChange}
              onBlur={this.checkInput}
            />
          </div>
          <div className="half">
            <label>SAT Score</label>
            <span className="error-span"></span>
            <input
              type="text"
              className="sat"
              placeholder="Enter SAT Score"
              name="sat"
              ref="sat"
              required={"true"}
              value={this.state.sat}
              data-error-message={
                "SAT field must not be blank - N/A is a valid response."
              }
              data-min-length={"2"}
              onChange={this.handleChange}
              onBlur={this.checkInput}
            />
          </div>
          <div className="half">
            <label>ACT Score:</label>
            <span className="error-span"></span>
            <input
              type="text"
              className="sat"
              placeholder="Enter ACT Score"
              name="act_score"
              ref="act_score"
              required={true}
              value={this.state.act_score}
              data-error-message={
                "ACT field must not be blank - N/A is a valid response."
              }
              data-min-length={"1"}
              onChange={this.handleChange}
              onBlur={this.checkInput}
            />
          </div>
          <div className="half">
            <p>Graduation Year</p>
            <select
              onChange={this.handleChange}
              name={"graduation_year"}
              className={"grad-year field"}
              data-name={"graduation-year"}
            >
              {this.setUpGradYearOptions()}
            </select>
          </div>
        </div>
      );
    }
  };

  setUpGradYearOptions = () => {
    let gradYears = [
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "N/A",
    ];
    let options = [];
    for (let gradYear of gradYears) {
      options.push(
        <option value={gradYear} key={gradYear}>
          {gradYear}
        </option>
      );
    }
    return options;
  };

  setUpShirtSizeOptions = () => {
    let shirtSizes = [
      "N/A",
      "Y-Small",
      "Y-Medium",
      "Y-Large",
      "Small",
      "Medium",
      "Large",
      "X-Large",
    ];
    let shirtOptions = [];
    for (let shirtSize of shirtSizes) {
      shirtOptions.push(
        <option value={shirtSize} key={shirtSize}>
          {shirtSize}
        </option>
      );
    }
    return shirtOptions;
  };

  addShirtField = () => {
    if (this.props.collect_tee_shirt_size) {
      return (
        <div className="camper-info-row">
          <div className="half">
            <p>Shirt Size (if applicable): *</p>
            <span className="error-span"></span>
            <select
              onChange={this.handleChange}
              name={"shirt_size"}
              className={"shirt-size field"}
              data-name={"shirt_size"}
            >
              {this.setUpShirtSizeOptions()}
            </select>
          </div>
        </div>
      );
    }
  };

  checkInput = (e) => {
    const itemClass = e.target.className;
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    return this.showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      const errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      return $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      return $(errorSpan).html("");
    }
  };

  positionChange = (positionValue) => {
    const fieldName = "position";
    return this.setState({ [fieldName]: positionValue });
  };

  handleChange = (e) => {
    const { name } = e.target;
    return this.setState({ [name]: e.target.value });
  };

  saveAndContinue = (e) => {
    e.preventDefault();

    const data = {
      height: this.state.height,
      weight: this.state.weight + "lbs",
      position: this.state.position,
      club_team: this.state.club_team,
      gpa: this.state.gpa,
      sat: this.state.sat,
      act_score: this.state.act_score,
      graduation_year: this.state.graduation_year,
      roommate_preference: this.state.roommate_preference,
      peers_attending: this.state.peers_attending,
      shirt_size: this.state.shirt_size,
    };
    this.props.saveValues(data);
    return this.props.nextStep();
  };

  render() {
    return (
      <form className={"new-step-form"}>
        <h1 className={"form-heading"}>
          {"Step 3. " +
            this.capitalizeString(this.props.sport) +
            " Information"}
        </h1>
        <div className={"camper-info-row"}>
          <div className={"half"}>
            <span className={"error-span"}></span>
            <label className={"h"}>{"Position: *"}</label>
            <PositionDropdown
              onChange={this.positionChange}
              value={this.state.position}
              sport={this.props.sport}
              full_price_points={this.props.full_position_price_points}
            />
          </div>
          <div className={"half"}>
            <span className={"error-span"}></span>
            <label>{"Club Team: *"}</label>
            <input
              type={"text"}
              className={"club-team full-field"}
              placeholder={"Club Team"}
              name={"club_team"}
              ref={"club_team"}
              required={"true"}
              data-error-message={
                "Club Team must be at least one character - N/A is a valid response."
              }
              data-min-length={"1"}
              value={this.state.club_team}
              onChange={this.handleChange}
              onBlur={this.checkInput}
            />
          </div>
        </div>
        <div className={"camper-info-row"}>
          <span className={"error-span"}></span>
          <p>{"Height: *"}</p>
          <HeightField
            name={"height"}
            feet={1}
            inches={0}
            ref={"height"}
            value={this.state.height}
            onChange={this.heightChanged}
          />
        </div>
        <div className={"camper-info-row"}>
          <div className={"half"}>
            <span className={"error-span"}></span>
            <textarea
              rows={{ 5: 5 }}
              className={"allergies full-field"}
              placeholder={"Please list any peers attending...."}
              name={"peers_attending"}
              ref={"peers_attending"}
              onChange={this.handleChange}
              onBlur={this.checkInput}
              value={this.state.peers_attending}
            ></textarea>
          </div>
          {this.addRoommatePreferenceField()}
        </div>
        <div className={"camper-info-row"}>{this.addIdCampFields()}</div>
        <div className={"camper-info-row"}>{this.addShirtField()}</div>
        <div className={"camper-info-row"}>
          <button
            type={"submit"}
            className={"button button-primary create"}
            disabled={!this.valid()}
            style={this.props.buttonStyles}
            onClick={this.saveAndContinue}
          >
            {"Next Step"}
          </button>
        </div>
      </form>
    );
  }
}
