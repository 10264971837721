import * as React from "react";
import * as ReactDom from "react-dom";

import CamperInfoForm from "./camper_info_form.js";
import MedicalForm from "./medical_form.js";
import SportForm from "./sport_form.js";
import LiabilityWaiverForm from "./liability_waiver_form.js";
import PaymentForm from "./payment_form.js";

import HockeyForm from "./hockey_form.js";
import BballForm from "./bball_form.js";

import ExciteInfoForm from "./excite_info_form.js";
import ExciteMedicalForm from "./excite_medical_form.js";
import SanDiegoStateMedicalForm from "./san_diego_state_medical_form.js";
import TexasStateSportForm from "./texas_state_sport_form.js";
import SportsPerformanceForm from "./sports_performance_form.js";
import CovidInfoForm from "./covid_info_form.js";

export default class RegistrationSteps extends React.Component {
  static displayName = "RegistrationSteps";

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      camper_identifier: null,
      campers: {},
      camper_template: {
        first_name: "",
        last_name: "",
        birth_date: "",
        player_phone_number: "",
        email: "",
        medications: "",
        conditions: "",
        allergies: "",
        diabetes: null,
        asthma: null,
        sickle_cell_positive: false,
        glasses_or_contacts: false,
        special_needs: false,
        physician: "",
        physician_contact_number: "",
        physician_city: "",
        insurer: "",
        insurance_policy_holder: "",
        group_number: "",
        emergency_contact: "",
        emergency_contact_number: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        height: "",
        weight: "",
        position: "",
        gpa: "",
        sat: "",
        act_score: "",
        club_team: "",
        roommate_preference: "",
        peers_attending: "",
        shirt_size: "",
        graduation_year: "",
        liability_waiver: null,
        liability_waiver_signature: "",
        guardian_contact: "",
        guardian_contact_number: "",
        guardian_contact_relationship: "",
        guardian_contact_email: "",
        dietary_restrictions: "",
        physical_restrictions: "",
        notes: "",
        injury_history: "",
        training_experience: "",
        goals: "",
        sports: "",
        years_in_sport: "",
        years_of_training: "",
        vaccinated: false,
        vaccine_type: "Not Collected",
        vaccine_dose_one_date: null,
        vaccine_dose_two_date: null,
        positive_test_date: null,
        covid_positive: false,
        covid_cleared: false,
        custom_data: {},
      },
    };
    this.initializeValues = this.initializeValues.bind(this);
    this.getDataStore = this.getDataStore.bind(this);
    this.saveValues = this.saveValues.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  getDataStore() {
    let campers = this.state.campers();
    return campers;
  }

  initializeValues(data) {
    let camperIdentifier = Math.floor(Math.random() * 16777215).toString(16);
    let camperInfoWithNewValues = $.extend(
      {},
      this.state.camper_template,
      data
    );
    this.state.campers[camperIdentifier] = camperInfoWithNewValues;
    this.setState({ camper_identifier: camperIdentifier });
  }

  saveValues(data) {
    let camperId = this.state.camper_identifier;
    let campers = this.state.campers;
    let camperInfoWithNewValues = $.extend({}, campers[camperId], data);
    return (this.state.campers[camperId] = camperInfoWithNewValues);
  }

  createFullPricePointPositionArray = () => {
    const pricePoints = JSON.parse(this.props.public_price_points);
    let pricePointPositions = [];
    for (let i = 0; i < pricePoints.length; i++) {
      if (pricePoints[i].registration_count >= pricePoints[i].capacity) {
        pricePointPositions.push(pricePoints[i]);
      }
    }
    return pricePointPositions;
  };

  nextStep() {
    return this.setState({ step: this.state.step + 1 });
  }

  previousStep() {
    console.log("previous");
  }

  setDetailForm(event_type, sport, shirt_size) {
    return (
      <SportForm
        buttonStyles={this.props.button_styles}
        event_type={event_type}
        collect_tee_shirt_size={this.props.collect_tee_shirt_size}
        saveValues={this.saveValues}
        previousStep={this.previousStep}
        nextStep={this.nextStep}
        camper_identifier={this.state.camper_identifier}
        campers={this.state.campers}
        sport={sport}
        price_points={this.props.public_price_points}
        full_position_price_points={this.createFullPricePointPositionArray()}
      />
    );
  }

  setTexasStateDetailForm(event_type, sport, shirt_size) {
    return (
      <TexasStateSportForm
        buttonStyles={this.props.button_styles}
        event_type={event_type}
        collect_tee_shirt_size={this.props.collect_tee_shirt_size}
        saveValues={this.saveValues}
        previousStep={this.previousStep}
        nextStep={this.nextStep}
        camper_identifier={this.state.camper_identifier}
        campers={this.state.campers}
        sport={sport}
        price_points={this.props.public_price_points}
        full_position_price_points={this.createFullPricePointPositionArray()}
      />
    );
  }

  generateCamperIdentifier = () => {
    return Math.floor(Math.random() * 16777215).toString(16);
  };

  setCamperId(data) {
    return this.setState({ camperId: data.id });
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  setRegistrationFlow() {
    if (this.props.custom_registration_flow == false) {
      return this.traditionalRegistration();
    } else {
      switch (this.props.custom_registration_type) {
        case "excite":
          return this.exciteRegistrationFlow();
        case "sdsu":
          return this.sanDiegoStateRegistrationFlow();
        case "texas-state":
          return this.texasStateRegistrationFlow();
        case "wake-forest":
          return this.wakeForestRegistrationFlow();
        case "sports-performance":
          return this.sportsPerformanceRegistrationFlow();
        case "customized-data-collection":
          return this.customizedDataCollectionFlow();
        case "jmu-purple":
          return this.traditionalRegistration();
      }
    }
  }

  traditionalRegistration() {
    switch (this.state.step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={this.props.button_styles}
              collect_guardian_info={this.props.collect_guardian_info}
              category={this.props.category}
              saveValues={this.initializeValues}
              nextStep={this.nextStep}
              campers={this.state.campers}
              event_type={this.props.event_type}
              price_points={this.props.public_price_points}
              waitlist_price_point_ids={this.props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            {this.setDetailForm(
              this.props.event_type,
              this.props.sport,
              this.props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
              waiver={this.props.waiver}
              signature_name={this.props.signature_name}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={this.props.button_styles}
              fee_breakout={this.props.fee_breakout}
              fee_percentage={this.props.fee_percentage}
              camp_id={this.props.camp_id}
              event_title={this.props.event_title}
              price_points={this.props.public_price_points}
              primary_price_point={this.props.primary_price_point}
              primary_camp_price_cents={this.props.primary_price_cents}
              date_range={this.props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={this.state.camper_identifier}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              campers={this.state.campers}
            />
          </div>
        );
    }
  }

  exciteRegistrationFlow() {
    switch (this.state.step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <ExciteInfoForm
              buttonStyles={this.props.button_styles}
              collect_guardian_info={this.props.collect_guardian_info}
              saveValues={this.initializeValues}
              nextStep={this.nextStep}
              campers={this.state.campers}
              event_type={this.props.event_type}
              price_points={this.props.public_price_points}
              waitlist_price_point_ids={this.props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg liability-form"}>
            <ExciteMedicalForm
              stepLabel={this.state.step}
              buttonStyles={this.props.button_styles}
              collect_guardian_info={this.props.collect_guardian_info}
              saveValues={this.saveValues}
              nextStep={this.nextStep}
              campers={this.state.campers}
              event_type={this.props.event_type}
              price_points={this.props.public_price_points}
              waitlist_price_point_ids={this.props.waitlist_price_point_ids}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg liability-form"}>
            <LiabilityWaiverForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
              waiver={this.props.waiver}
              signature_name={this.props.signature_name}
            />
          </div>
        );
      case 4:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={this.props.button_styles}
              fee_breakout={this.props.fee_breakout}
              fee_percentage={this.props.fee_percentage}
              camp_id={this.props.camp_id}
              event_title={this.props.event_title}
              price_points={this.props.public_price_points}
              primary_price_point={this.props.primary_price_point}
              primary_camp_price_cents={this.props.primary_price_cents}
              date_range={this.props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={this.state.camper_identifier}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              campers={this.state.campers}
            />
          </div>
        );
    }
  }

  sanDiegoStateRegistrationFlow() {
    switch (this.state.step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={this.props.button_styles}
              saveValues={this.initializeValues}
              nextStep={this.nextStep}
              campers={this.state.campers}
              event_type={this.props.event_type}
              price_points={this.props.public_price_points}
              waitlist_price_point_ids={this.props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <SanDiegoStateMedicalForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            {this.setDetailForm(
              this.props.event_type,
              this.props.sport,
              this.props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className={"event-reg liability-form"}>
            <LiabilityWaiverForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
              waiver={this.props.waiver}
              signature_name={this.props.signature_name}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={this.props.button_styles}
              fee_breakout={this.props.fee_breakout}
              fee_percentage={this.props.fee_percentage}
              camp_id={this.props.camp_id}
              event_title={this.props.event_title}
              price_points={this.props.public_price_points}
              primary_price_point={this.props.primary_price_point}
              primary_camp_price_cents={this.props.primary_price_cents}
              date_range={this.props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={this.state.camper_identifier}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              campers={this.state.campers}
            />
          </div>
        );
    }
  }
  sportsPerformanceRegistrationFlow() {
    switch (this.state.step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={this.props.button_styles}
              collect_guardian_info={this.props.collect_guardian_info}
              category={this.props.category}
              saveValues={this.initializeValues}
              nextStep={this.nextStep}
              campers={this.state.campers}
              event_type={this.props.event_type}
              price_points={this.props.public_price_points}
              waitlist_price_point_ids={this.props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            <SportsPerformanceForm
              buttonStyles={this.props.button_styles}
              event_type={this.props.event_type}
              custom_registration_type={this.props.custom_registration_type}
              collect_tee_shirt_size={this.props.collect_tee_shirt_size}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
              sport={this.props.sport}
              price_points={this.props.public_price_points}
              full_position_price_points={this.createFullPricePointPositionArray()}
            />
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
              waiver={this.props.waiver}
              signature_name={this.props.signature_name}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={this.props.button_styles}
              fee_breakout={this.props.fee_breakout}
              fee_percentage={this.props.fee_percentage}
              camp_id={this.props.camp_id}
              event_title={this.props.event_title}
              price_points={this.props.public_price_points}
              primary_price_point={this.props.primary_price_point}
              primary_camp_price_cents={this.props.primary_price_cents}
              date_range={this.props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={this.state.camper_identifier}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              campers={this.state.campers}
            />
          </div>
        );
    }
  }
  customizedDataCollectionFlow() {
    switch (this.state.step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={this.props.button_styles}
              collect_guardian_info={this.props.collect_guardian_info}
              category={this.props.category}
              saveValues={this.initializeValues}
              nextStep={this.nextStep}
              campers={this.state.campers}
              event_type={this.props.event_type}
              price_points={this.props.public_price_points}
              waitlist_price_point_ids={this.props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            {this.setDetailForm(
              this.props.event_type,
              this.props.sport,
              this.props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
              waiver={this.props.waiver}
              signature_name={this.props.signature_name}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={this.props.button_styles}
              fee_breakout={this.props.fee_breakout}
              fee_percentage={this.props.fee_percentage}
              camp_id={this.props.camp_id}
              event_title={this.props.event_title}
              price_points={this.props.public_price_points}
              primary_price_point={this.props.primary_price_point}
              primary_camp_price_cents={this.props.primary_price_cents}
              date_range={this.props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={this.state.camper_identifier}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              campers={this.state.campers}
            />
          </div>
        );
    }
  }
  texasStateRegistrationFlow() {
    switch (this.state.step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={this.props.button_styles}
              collect_guardian_info={this.props.collect_guardian_info}
              category={this.props.category}
              saveValues={this.initializeValues}
              nextStep={this.nextStep}
              campers={this.state.campers}
              event_type={this.props.event_type}
              price_points={this.props.public_price_points}
              waitlist_price_point_ids={this.props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            {this.setTexasStateDetailForm(
              this.props.event_type,
              this.props.sport,
              this.props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
              waiver={this.props.waiver}
              signature_name={this.props.signature_name}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={this.props.button_styles}
              fee_breakout={this.props.fee_breakout}
              fee_percentage={this.props.fee_percentage}
              camp_id={this.props.camp_id}
              event_title={this.props.event_title}
              price_points={this.props.public_price_points}
              primary_price_point={this.props.primary_price_point}
              primary_camp_price_cents={this.props.primary_price_cents}
              date_range={this.props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={this.state.camper_identifier}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              campers={this.state.campers}
            />
          </div>
        );
    }
  }

  wakeForestRegistrationFlow() {
    switch (this.state.step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={this.props.button_styles}
              collect_guardian_info={this.props.collect_guardian_info}
              category={this.props.category}
              saveValues={this.initializeValues}
              nextStep={this.nextStep}
              campers={this.state.campers}
              event_type={this.props.event_type}
              price_points={this.props.public_price_points}
              waitlist_price_point_ids={this.props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            {this.setDetailForm(
              this.props.event_type,
              this.props.sport,
              this.props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className="event-reg">
            <CovidInfoForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
            />
          </div>
        );
      case 5:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={this.props.button_styles}
              saveValues={this.saveValues}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              camper_identifier={this.state.camper_identifier}
              campers={this.state.campers}
              waiver={this.props.waiver}
              signature_name={this.props.signature_name}
            />
          </div>
        );
      case 6:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={this.props.button_styles}
              fee_breakout={this.props.fee_breakout}
              fee_percentage={this.props.fee_percentage}
              camp_id={this.props.camp_id}
              event_title={this.props.event_title}
              price_points={this.props.public_price_points}
              primary_price_point={this.props.primary_price_point}
              primary_camp_price_cents={this.props.primary_price_cents}
              date_range={this.props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={this.state.camper_identifier}
              previousStep={this.previousStep}
              nextStep={this.nextStep}
              campers={this.state.campers}
            />
          </div>
        );
    }
  }

  render() {
    return (
      <div className="registration-flow">{this.setRegistrationFlow()}</div>
    );
  }
}
