var React = require("react")
var ReactDom = require("react-dom")
var CartRegistration = require('./cart_registration.js');

class ShoppingCart extends React.Component{
  constructor(props) {
      super(props);
      console.log(props)
      this.state = {
        grand_total: 0,
        price_points: []
      };
      this.pricePointDataById = this.pricePointDataById.bind(this);
    }
  componentDidMount(){
    let price_points = JSON.parse(this.props.price_points)
    this.setState({ price_points: price_points });
    console.log(price_points)
    // let price_point = price_points.filter(object => object.id.includes())
  }
  calculateTotalPriceCents(){
    let registrations = this.props.registrations
    console.log(registrations)
    
  }
  pricePointDataById(id){
    console.log(id)
    let price_point_data = this.state.price_points.find(price_point => price_point.id === id)
    let title = price_point_data.title
    let price_cents = parseInt(price_point_data.price_cents)
    let price = this.formatCurrency((price_cents/100))
    return { title: title, price: price }
  }
  formatCurrency(number){
    return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
  render() {
    return (<div className="shop-cart">
              <h3 className="cart-heading">Your Order</h3>
              <div className="registrations-container">
                {Array.from(this.props.registrations).map((registration, key) =>
                  <CartRegistration key={key} first_name={registration.attendee_information.first_name} last_name={registration.attendee_information.last_name} price_point_data={this.pricePointDataById(registration.price_point_id)} />
                )}
              </div>
              <p>{this.state.total}</p>
            </div>);
  };
};

module.exports = ShoppingCart;