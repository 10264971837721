// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import * as React from "react";
import * as ReactDom from "react-dom";
import Toggle from "react-toggle";
import "react-toggle/style.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class CovidInfoForm extends React.Component {
  static displayName = "CovidInfoForm";

  constructor(props) {
    super(props);
    this.state = {
      vaccinated: false,
      vaccine_type: "Pfizer",
      vaccine_dose_one_date: new Date(),
      vaccine_dose_two_date: new Date(),
      positive_test_date: new Date(),
      covid_positive: false,
      covid_cleared: false,
    };
  }

  componentDidMount() {
    console.log("alright alright alright.");
  }

  checkInput = (e) => {
    let itemClass = e.target.className;
    let item = e.target;
    // console.log(e.target.type)
    let val = e.target.value;
    let errorSpan = item.parentElement.querySelector(".error-span");
    return this.showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      var errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      return $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      return $(errorSpan).html("");
    }
  };

  validateEmail = (email) => {
    var emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  };

  validateLength = (field, requiredLength) => {
    if (field.length > requiredLength) {
      return true;
    }
  };

  valid = () => {
    return true;
  };

  handleToggleChange = (e) => {
    const { name } = e.target;
    console.log(name);
    console.log(e.target.checked);
    return this.setState({ [name]: e.target.checked });
  };

  handleChange = (e) => {
    const { name } = e.target;
    return this.setState({ [name]: e.target.value });
  };

  handleDateChange = (date, name, e) => {
    return this.setState({ [name]: date });
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    const data = {
      vaccinated: this.state.vaccinated,
      vaccine_type: this.state.vaccine_type,
      vaccine_dose_one_date: this.state.vaccine_dose_one_date,
      vaccine_dose_two_date: this.state.vaccine_dose_two_date,
      positive_test_date: this.state.positive_test_date,
      covid_positive: this.state.covid_positive,
      covid_cleared: this.state.covid_cleared,
    };
    this.props.saveValues(data);
    return this.props.nextStep();
  };

  render() {
    return (
      <form className={"new-step-form"} autoComplete={"off"}>
        <div className="row">
          <p htmlFor="vaccinated">Have you received the Covid-19 vaccine?</p>
          <Toggle
            id="vaccinated"
            name="vaccinated"
            value={this.state.vaccinated.toString()}
            onChange={this.handleToggleChange}
          />
        </div>
        {this.state.vaccinated && (
          <div className="parent-row row">
            <p htmlFor="vaccinated">Which vaccine did you receive?</p>
            <select
              name="vaccine_type"
              value={this.state.value}
              onChange={this.handleChange}
            >
              <option value="pfizer">Pfizer</option>
              <option value="moderna">Moderna</option>
              <option value="johnson">Johnson & Johnson</option>
              <option value="other">Other</option>
              <option value="none">None</option>
            </select>
            <div className="row">
              <p>Vaccine #1 date</p>
              <DatePicker
                name={"vaccine_dose_one_date"}
                id="vaccine_dose_one_date"
                title={"vaccine_dose_one_date"}
                selected={this.state.vaccine_dose_one_date}
                onChange={(date, e) =>
                  this.handleDateChange(date, "vaccine_dose_one_date", e)
                }
              />
            </div>
            <div className="row">
              <p>Vaccine #2 date</p>
              <DatePicker
                name={"vaccine_dose_two_date"}
                id="vaccine_dose_two_date"
                title={"vaccine_dose_two_date"}
                selected={this.state.vaccine_dose_one_date}
                onChange={(date, e) =>
                  this.handleDateChange(date, "vaccine_dose_two_date", e)
                }
              />
            </div>
          </div>
        )}
        <div className="row">
          <p htmlFor="vaccinated">Have you tested positive for Covid-19?</p>
          <Toggle
            id="covid_positive"
            name="covid_positive"
            value={this.state.covid_positive.toString()}
            onChange={this.handleToggleChange}
          />
        </div>
        {this.state.covid_positive && (
          <div className="parent-row row">
            <div className="row">
              <p htmlFor="covid_cleared">
                If yes, have you since been fully cleared by your doctor to
                fully participate in individual activity?
              </p>
              <Toggle
                id="covid_cleared"
                name="covid_cleared"
                value={this.state.covid_cleared.toString()}
                onChange={this.handleToggleChange}
              />
            </div>
            <div className="row">
              <p>When did you test positive?</p>
              <DatePicker
                name={"positive_test_date"}
                id="positive_test_date"
                title={"positive_test_date"}
                selected={this.state.positive_test_date}
                onChange={(date, e) =>
                  this.handleDateChange(date, "positive_test_date", e)
                }
              />
            </div>
          </div>
        )}
        <button
          type={"submit"}
          className={"button button-primary create"}
          style={this.props.buttonStyles}
          disabled={!this.valid()}
          onClick={this.saveAndContinue}
        >
          {"Next Step"}
        </button>
      </form>
    );
  }
}
